import React, { useEffect, useState } from "react";
import { Box, HStack, Pressable, Text, VStack } from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import AntdModal from "@views/components/ui/antd_modal";
import { showNotification } from "@helpers/notify";
import {
  get_all_exam_play_list_query,
  useDynamicSelector,
  dynamicRequest,
  delete_exam_play_list_mutation,
  dynamicClear,
  update_exam_playlist_mutation,
} from "@services/redux";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import Wrapper from "@views/components/ui/wrapper";
import { Pagination, Popconfirm, Table, Typography, Button } from "antd";
import { useHistory, useParams } from "react-router-dom";
import ExamVideoPlayListDetails from "./exam_video_play_list_details";
import { ROUTES } from "@views/routes/my_routes";

const ExamVideoPlayList = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { exam_id, exam_name } = useParams();
  const [actionItem, setActionItem] = useState({});
  const [dialogVisible, setDialogVisible] = useState(false);
  const [current, setCurrent] = useState(1);
  const [current_limit, setCurrentLimit] = useState(10);
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [actionType, setActionType] = useState(null);
  const {
    items: get_all_exam_play_list,
    pagination,
    loading,
  } = useDynamicSelector("get_all_exam_play_list");

  const {
    loading: update_playlist_video_loading,
    status: update_playlist_video_success,
  } = useDynamicSelector("update_exam_playlist_videos");
  const {
    status: createStatus,
    error: createError,
    id: createId,
  } = useDynamicSelector("create_exam_play_list");
  const {
    status: updateStatus,
    error: updateError,
    id: updateId,
  } = useDynamicSelector("update_exam_play_list");

  const {
    loading: deleteLoading,
    status: deleteStatus,
    error: deleteError,
  } = useDynamicSelector("delete_exam_play_list");

  const handleAdd = () => {
    setActionItem({});
    setActionType("add");
    setModalOpen(true);
  };

  const handleEdit = (item) => {
    setActionItem(item);
    setActionType("edit");
    setModalOpen(true);
  };

  const handleDelete = (record) => {
    let keys = [{ key: "delete_exam_play_list", loading: true }];
    dispatch(
      dynamicRequest(keys, delete_exam_play_list_mutation, {
        id: record?.id,
      })
    );
  };

  const handlePlaylistRefresh = () => {
    let keys = [{ key: "update_exam_playlist_videos", loading: true }];
    dispatch(
      dynamicRequest(keys, update_exam_playlist_mutation, {
        exam_id: exam_id,
      })
    );
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    let keys = [{ key: "get_all_exam_play_list", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_exam_play_list_query, {
        page_number: current,
        page_limit: current_limit,
        exam_id,
      })
    );
  }, []);

  const loadExams = (should_load) => {
    let keys = [{ key: "get_all_exam_play_list", loading: should_load }];
    dispatch(
      dynamicRequest(keys, get_all_exam_play_list_query, {
        page_number: current,
        page_limit: current_limit,
        exam_id,
      })
    );
  };
  useEffect(() => {
    if (actionType === "add" && createStatus === "success") {
      handleModalClose();
      showNotification({
        type: "success",
        message: `${"Playlist"} added successfully`,
      });
      let keys = [{ key: "get_all_exam_play_list", loading: true }];

      dispatch(
        dynamicRequest(keys, get_all_exam_play_list_query, {
          page_number: current,
          page_limit: current_limit,
          exam_id,
        })
      );
      dispatch(dynamicClear("create_exam_play_list"));
    } else if (actionType === "edit" && updateStatus === "success") {
      handleModalClose();

      showNotification({
        type: "success",
        message: `${"Playlist"} updated successfully`,
      });
      dispatch(dynamicClear("update_exam_play_list"));

      let keys = [{ key: "get_all_exam_play_list", loading: true }];

      dispatch(
        dynamicRequest(keys, get_all_exam_play_list_query, {
          page_number: current,
          page_limit: current_limit,
          exam_id,
        })
      );
    } else if (update_playlist_video_success === "success") {
      showNotification({
        type: "success",
        message: `Exam playlist video updated successfully`,
      });
      dispatch(dynamicClear("update_exam_playlist_videos"));
    } else if (deleteStatus === "success") {
      showNotification({
        type: "success",
        message: `${"Playlist"} deleted successfully`,
      });
      dispatch(dynamicClear("delete_exam_play_list"));

      let keys = [{ key: "get_all_exam_play_list", loading: true }];

      dispatch(
        dynamicRequest(keys, get_all_exam_play_list_query, {
          page_number: current,
          page_limit: current_limit,
          exam_id,
        })
      );
    }
  }, [createStatus, updateStatus, deleteStatus, update_playlist_video_success]);

  const fetchData = (page_number, page_limit) => {
    let keys = [{ key: "get_all_exam_play_list", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_exam_play_list_query, {
        page_number: page_number,
        page_limit: page_limit,
        exam_id,
      })
    );
    setCurrent(page_number);
    setCurrentLimit(page_limit);
  };
  useEffect(() => {
    loadExams(true);
  }, []);
  const handleResponse = (data) => {
    history.push({
      pathname: `${ROUTES.VIDEO_LIST}/${data.id}`,
      state: data,
    });
  };

  let columns = [
    {
      title: t("table:Name"),
      dataIndex: "",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
      render: (data) => (
        <Pressable onPress={() => handleResponse(data)}>
          <Text color={"#0000ff"}>{data.name}</Text>
        </Pressable>
      ),
    },
    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "center",
      render: (record) => {
        return (
          <span>
            <Typography.Link
              onClick={() => handleEdit(record)}
              style={{ marginRight: 15 }}
            >
              <AiOutlineEdit size="20" />
              {/* <Text color="blue.400">Edit</Text> */}
            </Typography.Link>
            <Popconfirm
              title="Are you sure you want to delete this playlist?"
              onConfirm={() => handleDelete(record)}
            >
              <AiOutlineDelete size="20" />
              {/* <Text color="red.400">
                <a>Delete</a>
              </Text> */}
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  return (
    <>
      <Box top="10px" zIndex="2">
        <Text fontSize="md" fontWeight="bold">
          {exam_name} - {"Videos"}
        </Text>
      </Box>
      <Box width="100%" mt="10px" marginBottom="20px">
        <VStack space={5}>
          <HStack space={3} justifyContent="flex-end" m={3}>
            <Box key={`header-action`}>
              <Button
                type="primary"
                loading={update_playlist_video_loading}
                onClick={() => {
                  handlePlaylistRefresh();
                }}
              >
                Refresh
              </Button>
            </Box>
            <Box key={`header-action`}>
              <Button type="primary" onClick={handleAdd}>
                Add Playlist
              </Button>
            </Box>
          </HStack>
          <Wrapper>
            <Table
              loading={loading}
              columns={columns}
              dataSource={get_all_exam_play_list}
              pagination={false}
            />
          </Wrapper>
          {get_all_exam_play_list?.length && (
            <Pagination
              total={pagination?.total_count}
              showSizeChanger={true}
              current={current}
              hideOnSinglePage={false}
              onChange={fetchData}
            />
          )}

          <HStack justifyContent={"flex-end"}>
            <Button
              type="default"
              danger
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </Button>
          </HStack>
        </VStack>
      </Box>
      <AntdModal
        isOpen={modalOpen}
        onClose={handleModalClose}
        width={450}
        header={actionType === "add" ? t("Add Playlist") : t("Update Playlist")}
        component={
          <ExamVideoPlayListDetails
            label={actionType === "add" ? t("add") : t("update")}
            close={handleModalClose}
            initialValues={actionItem}
            exam_id={exam_id}
          />
        }
      />
    </>
  );
};
export default ExamVideoPlayList;
