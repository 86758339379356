import React, { useEffect, useRef, useState } from "react";
import VForm from "@views/components/ui/antd_form";
import { Button, Form, Popconfirm, Tooltip } from "antd";
import { useDispatch } from "react-redux";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory, useParams } from "react-router-dom";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { Button as NativeButton } from "native-base";
import AntdModal from "@views/components/ui/antd_modal";
import { GrAddCircle } from "react-icons/gr";
import { HiOutlineUpload } from "react-icons/hi";
import html2pdf from "html2pdf.js";
import {
  dynamicRequest,
  create_question_paper_mutation,
  useDynamicSelector,
  get_all_question_paper_section_query,
  dynamicClear,
  get_one_question_paper_query,
  update_question_paper_mutation,
  delete_question_paper_section_mutation,
  get_all_questions_query,
  get_all_question_paper_query,
  get_all_topics_query,
  get_all_question_paper_without_pagination,
} from "@services/redux";
import { useTranslation } from "react-i18next";
import { Box, HStack, Text, useBreakpointValue } from "native-base";
import Accordion from "@views/components/ui/antd_accordion/antd_accordian";
import QuestionSection from "./question_section";
import QuestionForm from "./question_details";
import QuestionPaperList from "./question_list";
import { showNotification } from "@helpers/notify";
import Dialog from "@views/components/ui/dialog";
import { showToast } from "@helpers/toast";
import BulkUploadModel from "./bulk_upload_question";
import PdfFormats from "./section_question_pdf";
import { margin, marginBottom, padding } from "styled-system";

const questionDetails = (props) => {
  const { t } = useTranslation();
  const { exam_id, id } = useParams();
  const [actionType, setActionType] = useState(null);
  const [sectionModal, setSectionModal] = useState(false);
  const [actionItem, setActionItem] = useState([]);
  const [bulk_upload_model, setBulkUploadModel] = useState(false);
  const [sectionId, setSectionId] = useState("");
  const [sectionType, setSectionType] = useState("objective");
  const dispatch = useDispatch();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();
  const [questionModal, setQuestionModal] = useState(false);
  const [current, setCurrent] = useState(1);
  const [question_list, setQuestionList] = useState([]);
  const [question_loading, set_question_loading] = useState(false);
  const tamil_question_ref = useRef();
  const english_question_ref = useRef();
  const english_question_answers_ref = useRef();
  const tamil_question_answers_ref = useRef();

  const { status: section_status, error: section_error } = useDynamicSelector(
    "create_question_paper_section"
  );
  const {
    loading: deleteLoading,
    status: deleteStatus,
    error: deleteError,
  } = useDynamicSelector("delete_question_paper_section");
  const {
    loading: updateLoading,
    status: updateStatus,
    error: updateError,
  } = useDynamicSelector("update_question_paper_section");

  const {
    loading: deleteQuestionPaperLoading,
    status: deleteQuestionPaperStatus,
    error: deleteQuestionPaperError,
  } = useDynamicSelector("delete_question_paper");

  const {
    loading: updateQuestionPaperLoading,
    status: updateQuestionPaperStatus,
    error: updateQuestionPaperError,
  } = useDynamicSelector("update_question_paper");
  const { status: question_status, error: question_error } =
    useDynamicSelector("create_question");

  const {
    id: question_paper_id,
    title,
    allowed_time,
    total_no_of_pages,
    type,
    sort_order,
    dependent_paper,
    question_paper_exam,
  } = useDynamicSelector("get_question_paper");
  const { items: get_all_question_paper_sections, loading } =
    useDynamicSelector("get_all_question_paper_sections");
  const { items: session_question, loading: session_question_loading } =
    useDynamicSelector("get_all_question_paper_sections_without_pagination");
  const { items: questions } = useDynamicSelector("get_all_question");
  console.log("session_question", session_question);
  const {
    id: create_id,
    status: create_status,
    error: create_error,
    loading: create_loading,
  } = useDynamicSelector("create_question_paper");

  const closeSectionModal = () => {
    setSectionModal(false);
  };
  const closeQuestionModal = () => {
    setQuestionModal(false);
  };

  const getQuestionPaper = () => {
    let keys = [{ key: "get_question_paper", loading: true }];
    let query = get_one_question_paper_query;
    let variables = {
      id: create_id || id,
    };
    dispatch(dynamicRequest(keys, query, variables));
  };

  const getAllQuestionPaperSections = () => {
    let keys = [{ key: "get_all_question_paper_sections", loading: true }];
    let query = get_all_question_paper_section_query;
    let variables = {
      page_number: current,
      page_limit: 10,
      question_paper_id: id,
    };
    dispatch(dynamicRequest(keys, query, variables));
  };

  const getAllQuestions = () => {
    let keys = [{ key: "get_all_questions", loading: true }];
    let query = get_all_questions_query;
    let variables = {
      page_number: current,
      page_limit: 10,
      section_id: sectionId,
    };
    dispatch(dynamicRequest(keys, query, variables));
  };
  const getAllQuestionsWithoutPagination = (id) => {
    let keys = [
      {
        key: "get_all_question_paper_sections_without_pagination",
        loading: true,
      },
    ];
    let query = get_all_question_paper_without_pagination;
    let variables = {
      question_paper_id: question_paper_id,
    };
    dispatch(dynamicRequest(keys, query, variables));
  };

  const updateQuestionPaper = (values) => {
    let keys = [{ key: "update_question_paper", loading: true }];
    let query = update_question_paper_mutation;
    let variables = {
      id: question_paper_id,
      data: values,
    };
    dispatch(dynamicRequest(keys, query, variables));
  };
  const createQuestionPaper = (values) => {
    let keys = [{ key: "create_question_paper", loading: true }];
    let query = create_question_paper_mutation;
    let variables = {
      data: values,
    };
    dispatch(dynamicRequest(keys, query, variables));
  };

  const handleSubmit = (values) => {
    values.exam_id = exam_id;
    values.booklet_url = values.booklet_url?.[0]?.url;
    if (question_paper_id) {
      updateQuestionPaper(values);
    } else {
      createQuestionPaper(values);
    }
  };

  const columns = useBreakpointValue({
    base: 1,
    md: 2,
  });
  const handleChange = (b, a) => {};
  const handleBack = () => {
    history.push(`${ROUTES.QUESTIONS_LIST}/${exam_id}`);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };

  const handleEditQuestionPaperSection = (item) => {
    setActionItem(item);
    setSectionId(item.id);
    setActionType("edit");
    setSectionModal(true);
  };

  const toggleAccordion = (id) => {
    if (id) {
      setSectionId(id);
      setSectionType(
        get_all_question_paper_sections.find((section) => section.id === id)
          ?.type
      );
    }
  };

  const handleAdd = () => {
    dispatch(dynamicClear("get_question_paper"));
    setActionType("add");
    setActionItem({});
    setSectionModal(true);
  };
  const handleAddQuestion = (item) => {
    dispatch(dynamicClear("get_question_paper"));
    setActionItem({});
    setActionType("add");
    if (item?.id) setSectionId(item.id);
    setQuestionModal(true);
  };

  const handleDeleteQuestionPaperSection = (record) => {
    let keys = [{ key: "delete_question_paper_section", loading: true }];
    dispatch(
      dynamicRequest(keys, delete_question_paper_section_mutation, {
        id: record?.id,
      })
    );
  };

  const getAllQuestionPaper = () => {
    let keys = [{ key: "get_all_question", loading: true }];
    let query = get_all_question_paper_query;
    let variables = { exam_id: exam_id };
    dispatch(dynamicRequest(keys, query, variables));
  };

  useEffect(() => {
    let temp = [];
    if (questions) {
      temp = questions?.filter((x) => x.id !== id);
    }
    setQuestionList(temp);
  }, [questions]);
  useEffect(() => {
    if (create_error) {
      showToast({
        type: "Failure",
        message: create_error,
      });
      dispatch(dynamicClear("create_question_paper"));
    }
    if (create_status === "success") {
      showToast({
        type: "Success",
        message: "Question Paper Created Successfully",
      });
      getQuestionPaper();
      dispatch(dynamicClear("create_question_paper"));
      history.push(`${ROUTES.QUESTIONS_DETAILS}/${exam_id}/${create_id}`);
      getAllQuestionPaper();
    }

    if (updateQuestionPaperStatus === "success") {
      showToast({
        type: "success",
        message: `Question paper update successfully`,
      });
      dispatch(dynamicClear("update_question_paper"));
      getAllQuestionPaper();
    }
  }, [
    create_status,
    create_error,
    deleteQuestionPaperStatus,
    updateQuestionPaperStatus,
  ]);

  useEffect(() => {
    if (section_error) {
      showToast({
        type: "Failure",
        message: section_error,
      });
    }

    if (section_status == "success") {
      showToast({
        type: "Success",
        message: "Question Paper Section Created Successfully",
      });
      closeSectionModal();
      dispatch(dynamicClear("create_question_paper_section"));
      getAllQuestionPaperSections();
    }
    if (deleteStatus === "success") {
      showToast({
        type: "success",
        message: `Question Paper Section deleted successfully`,
      });
      dispatch(dynamicClear("delete_question_paper_section"));
      getAllQuestionPaperSections();
    }
    if (updateStatus === "success") {
      showToast({
        type: "Success",
        message: "Question Paper Section Update Successfully",
      });
      dispatch(dynamicClear("update_question_paper_section"));
      closeSectionModal();
      getAllQuestionPaperSections();
    }
  }, [section_status, section_error, deleteStatus, updateStatus]);
  useEffect(() => {
    if (question_status === "success") {
      setQuestionModal(false);
      showToast({
        type: "Success",
        message: "Question Created Successfully",
      });
      closeQuestionModal();
      getAllQuestions();
      dispatch(dynamicClear("create_question"));
    }
  }, [question_status]);

  useEffect(() => {
    form.setFieldsValue({
      title,
      total_no_of_pages,
      allowed_time,
      type,
      sort_order,
      dependent_paper_id: dependent_paper?.id,
    });
  }, [
    question_paper_id,
    total_no_of_pages,
    title,
    allowed_time,
    sort_order,
    dependent_paper,
  ]);

  useEffect(() => {
    let keys = [{ key: "get_all_topics", loading: true }];
    dispatch(dynamicRequest(keys, get_all_topics_query));
    getAllQuestionPaperSections();
    getQuestionPaper();

    getAllQuestionPaper();
  }, []);

  useEffect(() => {
    if (question_paper_id) {
      getAllQuestionsWithoutPagination();
    }
  }, [question_paper_id]);

  const handleBulkUpload = () => {
    setBulkUploadModel(true);
  };

  const genExtra = (e) => (
    <span>
      <Tooltip title={"Questions Upload"} placement="top">
        <HiOutlineUpload
          size={20}
          style={{ marginRight: 15 }}
          onClick={() => handleBulkUpload(e)}
        />
      </Tooltip>
      <Tooltip title={"Add Question"} placement="top">
        <GrAddCircle
          size={20}
          style={{ marginRight: 15 }}
          onClick={() => handleAddQuestion(e)}
        />
      </Tooltip>
      <Tooltip title={"Edit Question"} placement="top">
        <AiFillEdit
          size={20}
          style={{ marginRight: 15 }}
          onClick={() => handleEditQuestionPaperSection(e)}
        />
      </Tooltip>
      <Tooltip title={"Delete Question"} placement="top">
        <Popconfirm
          title="Sure to delete?"
          onConfirm={() => handleDeleteQuestionPaperSection(e)}
        >
          <AiFillDelete size={20} />
        </Popconfirm>
      </Tooltip>
    </span>
  );
  const handleBulkUploadModel = () => {
    setBulkUploadModel(false);
  };

  const english_question_pdf = () => {
    set_question_loading(true);
    if (session_question?.length !== 0 && !session_question_loading) {
      const element = english_question_ref.current;
      // Ensure the content is fully loaded
      const opt = {
        padding: 10,
        margin: 5,
        filename: `English session question.pdf`,
        image: { type: "jpeg", quality: 1 },
        html2canvas: { scale: 1 },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
          fontSize: 8,
        },
        pagebreak: { mode: ["avoid-all"] }, // Avoid page breaks inside all elements
      };
      html2pdf().set(opt).from(element).save();

      set_question_loading(false);
    }
  };

  const tamil_question_pdf = () => {
    set_question_loading(true);
    if (session_question?.length !== 0 && !session_question_loading) {
      const element = tamil_question_ref.current;
      // Ensure the content is fully loaded
      const opt = {
        padding: 10,
        margin: 5,
        filename: `Tamil session question.pdf`,
        image: { type: "jpeg", quality: 1 },
        html2canvas: { scale: 1 },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
          fontSize: 8,
        },
        pagebreak: { mode: ["avoid-all"] }, // Avoid page breaks inside all elements
      };

      html2pdf().set(opt).from(element).save();

      set_question_loading(false);
    }
  };
  const tamil_question_answer_pdf = () => {
    if (session_question?.length !== 0 && !session_question_loading) {
      const element = tamil_question_answers_ref.current;
      // Ensure the content is fully loaded
      const opt = {
        margin: 4.5,
        filename: `${title} - Tamil question answers.pdf`,
        image: { type: "jpeg", quality: 1 },
        html2canvas: { scale: 1 },
        jsPDF: {
          unit: "mm",
          format: "letter",
          orientation: "portrait",
          fontSize: 8,
        },
      };

      html2pdf().set(opt).from(element).save();
    }
  };

  const english_question_answer_pdf = () => {
    if (session_question?.length !== 0 && !session_question_loading) {
      const element = english_question_answers_ref.current;
      // Ensure the content is fully loaded
      const opt = {
        margin: 4.5,
        filename: `${title} - English question answer.pdf`,
        image: { type: "jpeg", quality: 1 },
        html2canvas: { scale: 1 },
        jsPDF: {
          unit: "mm",
          format: "letter",
          orientation: "portrait",
          fontSize: 8,
        },
      };

      html2pdf().set(opt).from(element).save();
    }
  };

  const handleDownloadPDF = () => {
    set_question_loading(true);

    setTimeout(() => {
      set_question_loading(false);
      tamil_question_pdf();
      english_question_pdf();
      tamil_question_answer_pdf();
      english_question_answer_pdf();
    }, 5000);
    // set_question_loading(false)
  };

  return (
    <Form
      form={form}
      name={"Question Details"}
      layout={"vertical"}
      onFinish={handleSubmit}
      onValuesChange={handleChange}
      className="user_form ant-select-selector ant-select-selection-search"
    >
      <Box zIndex="2" bg="white" marginTop={10}>
        <Text fontSize="md" fontWeight="bold">
          {"Question Paper Details"}
        </Text>
      </Box>{" "}
      <Box>
        <VForm.Grid columns={columns} spacingY={20} spacingX={10}>
          <VForm.TextBox
            label={t("Title")}
            field={"title"}
            rules={[
              {
                required: true,
                message: "Title is Required",
              },
            ]}
          />
          <VForm.Select
            label={t("Type")}
            field={"type"}
            rules={[
              {
                required: true,
                message: "Type is Required",
              },
            ]}
            options={[
              { id: "objective", name: "Objective" },
              { id: "descriptive", name: "Descriptive" },
              {
                id: "objective_and_descriptive",
                name: "Objective & Descriptive",
              },
            ]}
          />
          <VForm.Number
            label={t("Allowed Time (mins)")}
            field={"allowed_time"}
          />
          <VForm.Number
            label={t("Total No of Pages")}
            field={"total_no_of_pages"}
          />
          <VForm.Number
            label={t("Sort Order")}
            field={"sort_order"}
            rules={[
              {
                required: true,
                message: "Sort Order is Required",
              },
            ]}
          />
          <VForm.File label={t("Booklet Format")} field={"booklet_url"} />
          <VForm.Select
            label={t("Dependent Paper")}
            field={"dependent_paper_id"}
            isMulti={false}
            options={question_list}
            labelField={"title"}
            valueField={"id"}
          />
        </VForm.Grid>
        <HStack space="10" justifyContent={"flex-end"} marginRight="40px">
          <VForm.Button
            loading={updateQuestionPaperLoading || create_loading}
            isLoading={updateQuestionPaperLoading || create_loading}
          >
            {t("Submit")}
          </VForm.Button>
          <Button onClick={handleBack}>{t("Back")}</Button>
        </HStack>
      </Box>
      {id && (
        <Box>
          <HStack justifyContent={"flex-end"} py={4} space={"10px"}>
            <Button
              onClick={handleDownloadPDF}
              type="primary"
              loading={question_loading}
            >
              {t("Export")}
            </Button>
            <Button onClick={handleAdd} type="primary">
              {t("Add section")}
            </Button>
          </HStack>
          <Accordion
            group={"question"}
            data={get_all_question_paper_sections}
            idField="id"
            parentField="parent_section_id"
            headerField={"name"}
            headerButtons={genExtra}
            loading={loading}
            itemComponent={
              <QuestionPaperList
                sectionId={sectionId}
                initialValues={actionItem}
              />
            }
            headerBgColor="#b6366d"
            headerActiveBgColor="#712e5a"
            onChange={(item) => {
              toggleAccordion(item);
            }}
          />

          <AntdModal
            id="question"
            isOpen={questionModal}
            onClose={() => {
              closeQuestionModal();
            }}
            width={"83vw"}
            noscroll={true}
            header={
              actionType === "add" ? t("Add Question") : t("Update Question")
            }
            component={
              <QuestionForm
                label={actionType === "add" ? t("add") : t("update")}
                close={closeQuestionModal}
                sectionId={sectionId}
                sectionType={sectionType}
                initialValues={actionItem}
              />
            }
            source={actionItem}
            size={"container"}
          />

          <AntdModal
            id="section"
            isOpen={sectionModal}
            onClose={closeSectionModal}
            width={1000}
            noscroll={true}
            header={
              actionType === "add" ? t("Add Section") : t("Update Section")
            }
            component={
              <QuestionSection
                label={actionType === "add" ? t("add") : t("update")}
                close={closeSectionModal}
                questionPaperId={id}
                initialValues={actionItem}
                actionType={actionType}
              />
            }
            source={actionItem}
            size={"container"}
          />
          <AntdModal
            isOpen={bulk_upload_model}
            onClose={handleBulkUploadModel}
            header={t("bulk_upload_question")}
            component={
              <BulkUploadModel
                onClose={handleBulkUploadModel}
                sectionId={sectionId}
              />
            }
          />
          <Dialog
            isOpen={dialogVisible}
            onClose={handleDialogClose}
            header={t("delete_section")}
            content={t("delete_section_content")}
            source={actionItem}
            actions={[
              {
                height: "40px",
                label: t("cancel"),
                colorScheme: "blueGray",
                variant: "ghost",
                onPress: handleDialogClose,
              },
              {
                height: "40px",
                width: "80px",
                label: t("delete"),
                colorScheme: "danger",
                variant: "outline",
                formErrorMessage: t("error:error_message"),
              },
            ]}
          />
          <div style={{ display: "none" }}>
            <PdfFormats.English
              question_ref={english_question_ref}
              session_question={session_question}
              question_paper={{
                name: title,
                allowed_time: allowed_time,
                total_no_of_pages: total_no_of_pages,
                type: type,
                dependent_paper: dependent_paper,
                question_paper_exam: question_paper_exam,
              }}
            />

            <PdfFormats.Tamil
              question_ref={tamil_question_ref}
              session_question={session_question}
              question_paper={{
                name: title,
                allowed_time: allowed_time,
                total_no_of_pages: total_no_of_pages,
                type: type,
                dependent_paper: dependent_paper,
                question_paper_exam: question_paper_exam,
              }}
            />
            <PdfFormats.EnglishAnswers
              question_ref={english_question_answers_ref}
              session_question={session_question}
              question_paper={{
                name: title,
                allowed_time: allowed_time,
                total_no_of_pages: total_no_of_pages,
                type: type,
                dependent_paper: dependent_paper,
                question_paper_exam: question_paper_exam,
              }}
            />
            <PdfFormats.TamilAnswers
              question_ref={tamil_question_answers_ref}
              session_question={session_question}
              question_paper={{
                name: title,
                allowed_time: allowed_time,
                total_no_of_pages: total_no_of_pages,
                type: type,
                dependent_paper: dependent_paper,
                question_paper_exam: question_paper_exam,
              }}
            />
          </div>
        </Box>
      )}
    </Form>
  );
};
export default questionDetails;
