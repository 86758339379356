import React, { useEffect } from "react";
import VForm from "@views/components/ui/antd_form";
import { Form, Button, Table, Card, Select } from "antd";
import { useDispatch } from "react-redux";
import {
  dynamicRequest,
  create_exam_mutation,
  update_exam_mutation,
  useDynamicSelector,
  get_all_for_exam_notes_topics_query,
  get_all_note_for_exam_notes_query,
  dynamicClear,
  update_exam_notes_order_mutation,
  get_all_note_query,
  get_all_topics_query,
} from "@services/redux";
import { useTranslation } from "react-i18next";
import { Box, Divider, HStack, Text, VStack } from "native-base";
import { useState } from "react";
import Sortable from "@views/components/ui/sortable/sortable";
import AntdModal from "@views/components/ui/antd_modal/antd_modal";
import TopicDetails from "../topic/topic_details";
import NoteDetails from "../notes/note_details";
import SortableCard from "./sortable_card";
import { AiFillPlusCircle, AiOutlinePlus } from "react-icons/ai";
import SortableCardNotes from "./sortable_card_notes";
import { showNotification } from "@helpers/notify";
import { format } from "crypto-js";
const ExamNotesDetails = (props) => {
  const { t } = useTranslation();
  const { initialValues, exam_id, exam_note, rerender } = props;
  const _ = require("lodash");
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const {
    loading: updateOrderLoading,
    status: updateOrderStatus,
    error: updateOrderError,
  } = useDynamicSelector("update_exam_notes_order");

  const { items: selected_topics } = useDynamicSelector(
    "get_all_topics_for_exam_notes"
  );
  const { items: topics_master } = useDynamicSelector("get_all_topics");

  const { items: selected_notes } = useDynamicSelector("get_all_ordered_notes");
  const { items: notes_master } = useDynamicSelector("get_all_notes");

  const { status: createStatus, error: createError } =
    useDynamicSelector("create_topic");
  const { status: createNotesStatus } = useDynamicSelector("create_note");

  const [filtered_topics, setFilteredTopics] = useState([]);
  const [filtered_notes, setFilteredNotes] = useState([]);
  const [selected_topic_list, setSelectedTopicList] = useState([]);
  const [selected_note_list, setSelectedNoteList] = useState([]);
  const [note_list, setNoteList] = useState([]);

  const [topic_id, setTopicId] = useState("");
  const [selected_topic_id, setSelectedTopicId] = useState("");

  const [modalOpen, setModalOpen] = useState(false);
  const [notesModalOpen, setNotesModalOpen] = useState(false);

  const [exam_notes, setExamNotes] = useState([]);
  const [note_id, setNoteId] = useState("");

  const [sortOrderList, setSortOrderList] = useState([]);

  const [selectedNotesList, setSelectedNotesList] = useState([]);

  const get_all_notes_for_drag = (topic) => {
    let keys = [{ key: "get_all_ordered_notes", loading: true }];
    let variables = {
      topic_id: topic,
      exam_id: exam_id,
    };
    // if (selectedTopic) {
    dispatch(
      dynamicRequest(keys, get_all_note_for_exam_notes_query, variables)
    );
    // }
  };
  const get_all_notes = (topic) => {
    let keys = [{ key: "get_all_notes", loading: true }];
    let variables = {
      topic_id: topic || selected_topic_id,
    };
    dispatch(dynamicRequest(keys, get_all_note_query, variables));
  };

  const get_all_topics = () => {
    let keys = [{ key: "get_all_topics", loading: true }];
    let variables = {
      // exam_id: exam_id,
    };
    dispatch(dynamicRequest(keys, get_all_topics_query, variables));
  };

  const get_all_topics_for_drag = () => {
    let keys = [{ key: "get_all_topics_for_exam_notes", loading: true }];
    let variables = {
      exam_id: exam_id,
    };
    dispatch(
      dynamicRequest(keys, get_all_for_exam_notes_topics_query, variables)
    );
  };

  const handleSortOrderChange = () => {
    let variables = {
      exam_id: exam_id,
      data: {
        items: note_list?.map((x, index) => {
          return {
            note_id: x.id || x.note_id,
            sort_order: x.sort_order || index + 1,
          };
        }),
      },
    };
    let keys = [{ key: "update_exam_notes_order", loading: true }];
    dispatch(
      dynamicRequest(keys, update_exam_notes_order_mutation, variables, "M")
    );
  };

  const handleAddTopics = () => {
    setModalOpen(true);
  };
  const handleTopicModalClose = () => {
    setModalOpen(false);
  };
  const handleAddNotes = () => {
    setNotesModalOpen(true);
  };
  const handleNotesModalClose = () => {
    setNotesModalOpen(false);
  };

  const handleSelectTopic = (topic) => {
    setSelectedTopicId(topic);
    console.log(topic);
    let temp_notes = JSON.parse(JSON.stringify(notes_master));
    let notes = temp_notes?.filter((x) => x.exam_topics?.[0]?.id === topic);
    let temp = note_list?.filter(
      (x) =>
        x.exam_note?.exam_topics?.[0]?.id === topic ||
        x.exam_topics?.[0]?.id === topic
    );
    console.log(notes_master);
    if (notes?.length) {
      let result = notes
        ?.filter(({ id }) => !temp?.some((x) => x.note_id === id))
        .map((x) => ({
          label: x.name,
          value: x.id,
          exam_topics: x.exam_topics,
        }));
      setFilteredNotes(result);
    }
    if (topic) {
      let temp = selected_note_list?.filter(
        (x) =>
          x.exam_note?.exam_topics?.[0]?.id === topic ||
          x.exam_topics?.[0]?.id === topic
      );
      temp = temp.sort((a, b) => a.sort_order - b.sort_order);

      setSelectedNoteList(temp);
    }
  };

  const handleRemoveNotes = (val) => {
    let temp = note_list.filter((x) => x.note_id !== val.note_id);

    setNoteList(temp);
  };

  const handleChangeSortOrder = (val) => {
    setSortOrderList(val);
    const sortOrderObj = {};
    if (Array.isArray(val)) {
      val.forEach((obj) => (sortOrderObj[obj.note_id] = obj.sort_order));
    }
    let notes = JSON.parse(JSON.stringify(note_list));
    let temp = notes.map((obj) => {
      console.log(obj);
      if (sortOrderObj.hasOwnProperty(obj.note_id)) {
        obj.sort_order = sortOrderObj[obj.note_id];
      }
      return obj;
    });
    console.log(temp);
    setNoteList(temp);
  };
  useEffect(() => {
    get_all_topics();
    get_all_topics_for_drag();
    get_all_notes();
    get_all_notes_for_drag();
  }, [exam_id, rerender]);

  useEffect(() => {
    if (createStatus === "success") {
      handleTopicModalClose();
      showNotification({
        type: "success",
        message: `${"Topic"} added successfully`,
      });
      get_all_topics();
      dispatch(dynamicClear("create_topic"));
    }
    if (createNotesStatus === "success") {
      handleNotesModalClose();
      showNotification({
        type: "success",
        message: `${"Note"} added successfully`,
      });

      dispatch(dynamicClear("create_note"));
      get_all_notes();
    }
    if (updateOrderStatus === "success") {
      props.close();
      showNotification({
        type: "success",
        message: `Notes Order changed successfully`,
      });

      dispatch(dynamicClear("update_exam_notes_order"));
    }
  }, [createStatus, createNotesStatus, updateOrderStatus]);

  useEffect(() => {
    if (topics_master?.length) {
      let result = topics_master
        ?.filter(({ id }) => !selected_topics?.some((x) => x.id === id))
        .map((x) => ({ label: x.name, value: x.id }));
      setFilteredTopics(result);
    }
    if (selected_topics?.length) {
      setSelectedTopicList(selected_topics);
    }
  }, [topics_master, selected_topics]);

  useEffect(() => {
    if (selected_notes) {
      setNoteList(selected_notes);
    }
  }, [selected_notes]);
  useEffect(() => {
    if (notes_master) {
      let notes = notes_master?.filter(
        (x) => x.exam_topics?.[0]?.id === selected_topic_id
      );
      if (notes?.length) {
        let result = notes
          ?.filter(
            ({ id }) => !selected_note_list?.some((x) => x.note_id === id)
          )
          .map((x) => ({
            label: x.name,
            value: x.id,
            exam_topics: x.exam_topics,
          }));
        setFilteredNotes(result);
      }
    }
  }, [notes_master]);
  useEffect(() => {
    if (note_list?.length && selected_topic_id) {
      let temp = note_list?.filter(
        (x) =>
          x.exam_note?.exam_topics?.[0]?.id === selected_topic_id ||
          x.exam_topics?.[0]?.id === selected_topic_id
      );
      temp = temp.sort((a, b) => a.sort_order - b.sort_order);
      setSelectedNoteList([...temp]);
    }
  }, [note_list, selected_topic_id]);

  const handleTopicChange = (topic) => {
    let temp = filtered_topics?.find((x) => x.value === topic);
    let values = [];
    let temp1 = filtered_topics.filter((x) => x.value !== topic);
    setFilteredTopics(temp1);
    values.push(...selected_topic_list, { id: temp.value, name: temp.label });
    setTopicId("");
    setSelectedTopicList(values);
  };
  const handleNoteChange = (topic) => {
    let temp = filtered_notes?.find((x) => x.value === topic);
    let values = [];
    let temp1 = filtered_notes.filter((x) => x.value !== topic);
    console.log(filtered_notes, temp1);
    setFilteredNotes(temp1);
    values.push(...note_list, {
      note_id: temp.value,
      name: temp.label,
      exam_topics: temp.exam_topics,
    });
    setNoteId("");
    // setSelectedNoteList(values);
    setNoteList(values);
  };
  return (
    <Box flex={1}>
      <HStack space="3" columns={2} spacingY={20} spacingX={10} mb="3">
        <Box flex={1}>
          <Text>{t("Topics")}</Text>
          <Select
            value={topic_id}
            options={filtered_topics}
            showSearch={true}
            // filterOption={(input, option) =>
            //   option.props.children
            //     ?.toLowerCase()
            //     ?.indexOf(input.toLowerCase()) >= 0 ||
            //   option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
            // }
            filterOption={(input, option) =>
              option.label?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0 ||
              option.label?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
            }
            onChange={handleTopicChange}
          />
        </Box>

        <Box alignSelf={"center"}>
          <Button type="link" onClick={handleAddTopics}>
            <AiFillPlusCircle size="30" />
          </Button>
        </Box>

        <Box flex={1}>
          <Text>{t("notes")}</Text>
          <Select
            value={note_id}
            options={filtered_notes}
            onChange={handleNoteChange}
            allowClear
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            showSearch={true}
          />
        </Box>
        <Box alignSelf={"center"}>
          <Button type="link" onClick={handleAddNotes}>
            <AiFillPlusCircle size="30" />
          </Button>
        </Box>
      </HStack>

      <Divider />

      <HStack space="7" mt="10">
        <Box flex={1}>
          <VStack space={5}>
            {selected_topic_list?.map((x) => (
              <SortableCard
                item={x}
                // removeTopicId={removeTopicId}
                handleSelect={handleSelectTopic}
                selectedTopic={selected_topic_id}
                // handleRemoveTopics={handleRemoveTopics}
              />
            ))}
          </VStack>
        </Box>
        <Box flex={1}>
          <Sortable
            items={selected_note_list}
            idField="note_id"
            onSortChange={(e) => {
              handleChangeSortOrder(e);
            }}
            sortField="sort_order"
            component={
              <SortableCardNotes handleRemoveNotes={handleRemoveNotes} />
            }
          />
        </Box>
      </HStack>

      <HStack space="5" justifyContent={"center"} marginRight="40px" mt="5">
        <Button
          loading={updateOrderLoading}
          onClick={handleSortOrderChange}
          type="primary"
        >
          {t("Submit")}
        </Button>
        <Button onClick={props.close}>{t("close")}</Button>
      </HStack>
      <AntdModal
        isOpen={modalOpen}
        onClose={handleTopicModalClose}
        width={450}
        header={t("Add Topic")}
        component={
          <TopicDetails close={handleTopicModalClose} initialValues={{}} />
        }
      />
      <AntdModal
        isOpen={notesModalOpen}
        onClose={handleNotesModalClose}
        width={450}
        header={t("Add Notes")}
        component={
          <NoteDetails
            close={handleNotesModalClose}
            initialValues={{}}
            exam_notes={true}
          />
        }
      />
    </Box>
  );
};
export default ExamNotesDetails;
