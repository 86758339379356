import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  VStack,
  Text,
  HStack,
  Pressable,
  Card,
  Center,
  IconButton,
} from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import {
  get_all_board_query,
  useDynamicSelector,
  delete_board_mutation,
  dynamicRequest,
  get_one_board_query,
  dynamicClear,
} from "@services/redux";
import BoardDetails from "./board_details";
import AntdModal from "@views/components/ui/antd_modal";
import { Button, Pagination, Popconfirm, Table, Typography } from "antd";
import { showNotification } from "@helpers/notify";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import SimpleGrid from "@views/components/ui/simple_grid/simple_grid";
import SearchBox from "@views/components/ui/search_box/search_box";

const BoardList = () => {
  const dispatch = useDispatch();
  const [actionType, setActionType] = useState(null);
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const [current, setCurrent] = useState(1);
  const [highlightComponent, setHighlightComponent] = useState();
  const [currentID, setCurrentId] = useState();
  const [hover, setHover] = useState(false);
  const [search, setSearch] = useState("");

  const {
    items: get_all_boards,
    loading: board_loading,
    pagination,
  } = useDynamicSelector("get_all_boards");
  const { status: createStatus, error: createError } =
    useDynamicSelector("create_board");
  const { status: updateStatus, error: updateError } =
    useDynamicSelector("update_board");

  const { status: deleteStatus, error: deleteError } =
    useDynamicSelector("delete_board");

  const handleAdd = () => {
    setActionItem({});
    setActionType("add");
    setModalOpen(true);
  };

  const handleEdit = (item) => {
    let temp = { ...item };

    if (item.icon)
      temp.icon = [
        {
          uid: "-1",
          name: "Icon",
          status: "done",
          url: item?.icon,
        },
      ];

    setActionItem(temp);
    setActionType("edit");
    setModalOpen(true);
  };

  const handleDelete = (record) => {
    let keys = [{ key: "delete_board", loading: true }];
    dispatch(
      dynamicRequest(keys, delete_board_mutation, {
        id: record?.id,
      })
    );
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleResponse = (data) => {
    history.push({
      pathname: `${ROUTES.EXAM_LIST}/${data.id}/${data.name}`,
      state: { key: `${data?.name}` },
    });
  };

  useEffect(() => {
    let keys = [{ key: "get_all_boards", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_board_query, {
        page_number: current,
        page_limit: 10,
        search_string: search,
      })
    );
  }, []);

  useEffect(() => {
    if (actionType === "add" && createStatus === "success") {
      handleModalClose();
      showNotification({
        type: "success",
        message: `Board added successfully`,
      });
      let keys = [{ key: "get_board", loading: true }];

      dispatch(dynamicRequest(keys, get_all_board_query, "M"));
      dispatch(dynamicClear("create_board"));
    } else if (actionType === "edit" && updateStatus === "success") {
      handleModalClose();

      showNotification({
        type: "success",
        message: `Board updated successfully`,
      });
      dispatch(dynamicClear("update_board"));

      let keys = [{ key: "get_board", loading: true }];

      dispatch(dynamicRequest(keys, get_all_board_query));
    } else if (deleteStatus === "success") {
      showNotification({
        type: "success",
        message: `Board deleted successfully`,
      });
      dispatch(dynamicClear("create_board"));

      let keys = [{ key: "delete_board", loading: true }];

      dispatch(dynamicRequest(keys, get_one_board_query));
    }
  }, [createStatus, updateStatus, deleteStatus]);

  const fetchData = (value) => {
    let keys = [{ key: "get_all_boards", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_board_query, {
        page_number: value,
        page_limit: 10,
        search_string: search,
      })
    );
    setCurrent(value);
  };
  const handleSearch = (search_txt) => {
    let keys = [{ key: "get_all_boards", loading: true }];

    dispatch(
      dynamicRequest(keys, get_all_board_query, {
        page_limit: 10,
        page_number: 1,
        search_string: search_txt,
      })
    );
    setSearch(search_txt);
  };
  return (
    <>
      <Box top="10px" zIndex="2">
        <Text fontSize="md" fontWeight="bold">
          {"Boards"}
        </Text>
      </Box>
      <Box width="100%" mt="10px" marginBottom="20px">
        <VStack space={5}>
          <HStack space={3} justifyContent="flex-end" m={3}>
            <Box>
              <Button type="primary" onClick={handleAdd}>
                Add Board
              </Button>
            </Box>
          </HStack>
          <HStack space={3} justifyContent="flex-end">
            <SearchBox
              label={t("table:Search")}
              width={{
                base: "300px",
                xs: "200px",
                sm: "200px",
                md: "300px",
              }}
              onSearch={handleSearch}
            />
          </HStack>
          <SimpleGrid
            maxw="100%"
            w="100%"
            columns={{
              base: 1,
              xs: 1,
              md: 3,
              sm: 1,
              lg: 4,
              xl: 4,
              "2xl": 6,
            }}
            spacingY={20}
            // spacingX={5}
          >
            {get_all_boards?.map((x, index) => {
              return (
                <Pressable
                  paddingX={2}
                  key={index}
                  onPress={() => {
                    setHighlightComponent(x.id);
                    handleResponse(x);
                  }}
                  onMouseEnter={() => {
                    setHover(true);
                    setCurrentId(x.id);
                  }}
                  onMouseLeave={() => {
                    setHover(false);
                    setCurrentId(-1);
                  }}
                >
                  <Box
                    mx="3"
                    width="265px"
                    minH="200px"
                    alignSelf={"center"}
                    bg="#ffff"
                    padding="2"
                    borderRadius="20px"
                    shadow="4"
                  >
                    <Center padding={"3"} flex={1} width={"100%"}>
                      <HStack flex={1}>
                        <Box flex={1}>
                          <img src={x.icon} alt={x.name} height="130px" />
                        </Box>
                        <Box
                          style={{
                            position: "absolute",
                            right: "0",
                          }}
                        >
                          {hover && currentID === x?.id ? (
                            <HStack
                              // justifyContent={"flex-end"}
                              style={{
                                position: "absolute",
                              }}
                            >
                              <IconButton
                                variant="unstyled"
                                icon={
                                  <AiOutlineDelete size={20} color={"red"} />
                                }
                                onPress={() => {
                                  handleDelete(x);
                                }}
                              />
                              <IconButton
                                variant="unstyled"
                                icon={
                                  <AiOutlineEdit size={20} color={"blue"} />
                                }
                                onPress={() => {
                                  handleEdit(x);
                                }}
                              />
                            </HStack>
                          ) : (
                            <Text h={10}> </Text>
                          )}
                        </Box>
                      </HStack>

                      <Box mt="3">
                        <Text
                          textAlign={"center"}
                          color={
                            highlightComponent === x.id ? "grey.700" : "black"
                          }
                          fontWeight={"600"}
                          fontSize={"18px"}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          {x.name}
                        </Text>
                      </Box>
                    </Center>
                  </Box>
                </Pressable>
              );
            })}
          </SimpleGrid>
          {/* {get_all_boards?.length && (
            <Pagination
              total={pagination?.total_count}
              showSizeChanger={true}
              current={current}
              hideOnSinglePage={false}
              onChange={fetchData}
            />
          )} */}
        </VStack>
      </Box>

      <AntdModal
        isOpen={modalOpen}
        onClose={handleModalClose}
        width={650}
        header={actionType === "add" ? t("Add Board") : t("Update Board")}
        component={
          <BoardDetails
            label={actionType === "add" ? t("add") : t("update")}
            close={handleModalClose}
            initialValues={actionItem}
          />
        }
      />
    </>
  );
};
export default BoardList;
