// import SuperAdminLayout from "@views/layouts/super_admin/super_admin_layout";
import ForgotPassword from "@views/pages/forgot_password";
import AccessRights from "@views/components/access_rights/access_rights";
import Login from "@views/pages/login";
import Register from "@views/pages/register";
import QuestionsDetails from "@views/pages/questions/question_papers_details";
import QuestionsList from "@views/pages/questions/questions_papers_list";
import EvaluatorsList from "@views/pages/evaluators/evaluators_list";
import EvaluatorsDetails from "@views/pages/evaluators/evaluators_details";
import layout1 from "@views/layouts/layout1";
import Redirector from "@views/pages/redirector";
import Policy from "@views/pages/privacy_policy";
import ResponsesList from "@views/pages/responses/responses_list";
import CandidatesList from "@views/pages/candidates/candidates_list";
import Dashboard from "@views/pages/dashboard/dashboard";
import SubmissionList from "@views/pages/evaluator/submission/submission_list";
import SubmissionDetails from "@views/pages/evaluator/submission/submission_details";
import EvaluatorDashboard from "@views/pages/evaluator/dashboard/evaluator_dashboard";
import NoteList from "@views/pages/notes/note_list";
import TopicList from "@views/pages/topic/topic_list";
import ExamList from "@views/pages/exam/exam_list";
import BoardList from "@views/pages/board/board_list";
import ExamCards from "@views/pages/exam/exam_cards";
import PreviousYearPaper from "@views/pages/previous_year_paper/previous_year_paper";
import ExamVideoPlayList from "@views/pages/exam_video_play_list/exam_video_play_list";
import VideosList from "@views/pages/exam_video_play_list/videoes_list";
import TextBookList from "@views/pages/text_book_board/text_book_board_list";
import ClassList from "@views/pages/classes/class_list";
import SubjectList from "@views/pages/subjects/subject_list";
import NotificationList from "@views/pages/notification/notification_list";
import InfographicTopicsList from "@views/pages/infographic_topics/infographic_topics_list";
import InfographicNotesList from "@views/pages/infographic_notes/infographic_notes_list";
import Report from "@views/pages/reports/report";

export const ROUTES = {
  REDIRECTOR: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  FORGOT_PASSWORD: "/forgot-password",
  SUBMISSION_LIST: "/submission-list",
  SUBMISSION_DETAIL: "/submission-detail",
  QUESTIONS_DETAILS: "/questions-details",
  QUESTIONS_LIST: "/questions-list",
  EVALUATORS_DETAILS: "/evaluators-details",
  EVALUATORS_LIST: "/evaluators-list",
  POLICY: "/privacy-policy",
  RESPONSES_LIST: "/responses_list",
  CANDIDATES_LIST: "/candidates-list",
  DASHBOARD: "/dashboard",
  EVALUATORS_DASHBOARD: "/evaluator-dashboard",
  TOPIC_LIST: "/topic-list",
  EXAM_LIST: "/exam-list",
  BOARD_LIST: "/board-list",
  NOTE_LIST: "/note-list",
  EXAM_CARDS: "/exam-cards",
  PREVIOUS_YEAR_PAPER: "/previous-year-papers",
  EXAM_VIDEO_PLAY_LIST: "/exam-video-playlist",
  VIDEO_LIST: "/video-list",
  TEXT_BOOK: "/text-book",
  CLASS_LIST: "/class-list",
  SUBJECT_LIST: "/subject-list",
  NOTIFICATION_LIST: "/notification-list",
  INFOGRAPHIC_TOPICS_LIST: "/infographic-topic-list",
  INFOGRAPHIC_NOTES_LIST: "/infographic-notes-list",
  REPORT: "/report",
};

const myRoutes = [
  {
    // : "/",
    path: ROUTES.REDIRECTOR,
    exact: true,
    page_key: "redirector",
    component: Login,
    authenticate: false,
  },
  {
    key: "Topics",
    component: layout1,
    authenticate: false,
    children: [
      {
        path: ROUTES.TOPIC_LIST,
        exact: true,
        page_key: "topic_list",
        component: TopicList,
        layout: layout1,
        authenticate: false,
      },

      {
        path: `${ROUTES.EXAM_LIST}/:board_id?/:name`,
        exact: true,
        page_key: "exam_list",
        component: ExamList,
        layout: layout1,
        authenticate: false,
      },
      {
        path: ROUTES.BOARD_LIST,
        exact: true,
        page_key: "board_list",
        component: BoardList,
        layout: layout1,
        authenticate: false,
      },

      {
        path: `${ROUTES.NOTE_LIST}/:topic_id`,
        exact: true,
        page_key: "note_list",
        component: NoteList,
        layout: layout1,
        authenticate: false,
      },
      {
        path: `${ROUTES.QUESTIONS_DETAILS}/:exam_id/:id?`,
        exact: true,
        page_key: "questions_details",
        component: QuestionsDetails,
        authenticate: false,
      },
      {
        path: `${ROUTES.QUESTIONS_LIST}/:exam_id?/:exam_name?`,
        exact: true,
        page_key: "questions_list",
        component: QuestionsList,
        layout: layout1,
        authenticate: false,
      },
      {
        path: `${ROUTES.EXAM_CARDS}/:exam_id?/:exam_name?`,
        exact: true,
        page_key: "questions_list",
        component: ExamCards,
        layout: layout1,
        authenticate: false,
      },

      {
        path: ROUTES.EVALUATORS_DETAILS,
        exact: true,
        page_key: "evaluators_details",
        component: EvaluatorsDetails,
        authenticate: false,
      },
      {
        path: ROUTES.EVALUATORS_LIST,
        exact: true,
        page_key: "evaluators_list",
        component: EvaluatorsList,
        layout: layout1,
        authenticate: false,
      },
      {
        path: `${ROUTES.RESPONSES_LIST}/:id`,
        exact: true,
        page_key: "responses_list",
        component: ResponsesList,
        layout: layout1,
        authenticate: false,
      },
      {
        path: ROUTES.CANDIDATES_LIST,
        exact: true,
        page_key: "candidates_list",
        component: CandidatesList,
        layout: layout1,
        authenticate: false,
      },
      {
        path: ROUTES.DASHBOARD,
        exact: true,
        page_key: "dashboard",
        component: Dashboard,
        layout: layout1,
        authenticate: false,
      },
      {
        path: ROUTES.NOTIFICATION_LIST,
        exact: true,
        page_key: "notification_list",
        component: NotificationList,
        layout: layout1,
        authenticate: false,
      },
      {
        path: ROUTES.INFOGRAPHIC_TOPICS_LIST,
        exact: true,
        page_key: "infographic_topics",
        component: InfographicTopicsList,
        layout: layout1,
        authenticate: false,
      },
      {
        path: ROUTES.REPORT,
        exact: true,
        page_key: "REPORT",
        component: Report,
        authenticate: false,
        layout: layout1,
      },
      {
        path: `${ROUTES.INFOGRAPHIC_NOTES_LIST}/:id`,
        exact: true,
        page_key: "infographic_notes",
        component: InfographicNotesList,
        layout: layout1,
        authenticate: false,
      },
      {
        path: `${ROUTES.PREVIOUS_YEAR_PAPER}/:exam_id?/:exam_name?`,
        exact: true,
        page_key: "previous-year-paper",
        component: PreviousYearPaper,
        authenticate: false,
        layout: layout1,
      },
      {
        path: `${ROUTES.EXAM_VIDEO_PLAY_LIST}/:exam_id?/:exam_name?`,
        exact: true,
        page_key: "exam-video-playlist",
        component: ExamVideoPlayList,
        authenticate: false,
        layout: layout1,
      },
      {
        path: `${ROUTES.VIDEO_LIST}/:id?`,
        exact: true,
        page_key: "video-list",
        component: VideosList,
        authenticate: false,
        layout: layout1,
      },
      {
        path: ROUTES.TEXT_BOOK,
        exact: true,
        page_key: "text-book",
        component: TextBookList,
        authenticate: false,
        layout: layout1,
      },
      {
        path: `${ROUTES.CLASS_LIST}/:board_id?`,
        exact: true,
        page_key: "class-list",
        component: ClassList,
        authenticate: false,
        layout: layout1,
      },
      {
        path: `${ROUTES.SUBJECT_LIST}/:board_id?/:class_id`,
        exact: true,
        page_key: "subject-list",
        component: SubjectList,
        authenticate: false,
        layout: layout1,
      },
    ],
  },

  {
    key: "Topics",
    component: layout1,
    authenticate: false,
    children: [
      {
        path: `${ROUTES.QUESTIONS_DETAILS}/:id`,
        exact: true,
        page_key: "questions_details",
        component: QuestionsDetails,
        authenticate: false,
      },
      {
        path: ROUTES.QUESTIONS_LIST,
        exact: true,
        page_key: "questions_list",
        component: QuestionsList,
        layout: layout1,
        authenticate: false,
      },

      {
        path: ROUTES.EVALUATORS_DETAILS,
        exact: true,
        page_key: "evaluators_details",
        component: EvaluatorsDetails,
        authenticate: false,
      },
      {
        path: ROUTES.EVALUATORS_LIST,
        exact: true,
        page_key: "evaluators_list",
        component: EvaluatorsList,
        layout: layout1,
        authenticate: false,
      },

      {
        path: ROUTES.CANDIDATES_LIST,
        exact: true,
        page_key: "candidates_list",
        component: CandidatesList,
        layout: layout1,
        authenticate: false,
      },
    ],
  },

  {
    path: `${ROUTES.SUBMISSION_LIST}/:status?`,
    exact: true,
    page_key: "submission_list",
    component: SubmissionList,
    authenticate: false,
  },
  {
    path: ROUTES.EVALUATORS_DASHBOARD,
    exact: true,
    page_key: "DASHBOARD",
    component: EvaluatorDashboard,
    authenticate: false,
  },
  {
    path: `${ROUTES.SUBMISSION_DETAIL}/:id?`,
    exact: true,
    page_key: "submission_list",
    component: SubmissionDetails,
    authenticate: false,
  },
  {
    path: ROUTES.LOGIN,
    exact: true,
    page_key: "login",
    component: Login,
    authenticate: false,
  },

  {
    path: ROUTES.REGISTER,
    page_key: "register",
    component: Register,
    authenticate: false,
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    page_key: "forgot_password",
    component: ForgotPassword,
    authenticate: false,
  },

  {
    path: ROUTES.POLICY,
    exact: true,
    page_key: "policy",
    component: Policy,
    authenticate: false,
  },

  {
    path: "/access-rights",
    exact: true,
    page_key: "access",
    component: AccessRights,
    authenticate: false,
  },
];

export default myRoutes;
