import { gql } from "@apollo/client";

export const create_infographic_topics = gql`
  mutation createInfographicTopic($json: create_infographic_topic_input) {
    createInfographicTopic(json: $json) {
      id
      status
      count
      error {
        status_code
        message
      }
    }
  }
`;

export const update_infographic_topic = gql`
  mutation updateInfographicTopic($json: update_infographic_topic_input) {
    updateInfographicTopic(json: $json) {
      id
      status
      count
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_infographic_topic = gql`
  mutation deleteInfographicTopic($json: delete_infographic_topic_input) {
    deleteInfographicTopic(json: $json) {
      id
      status
      count
      error {
        status_code
        message
      }
    }
  }
`;
export const get_one_infographic_topic = gql`
  query getInfographicTopic($json: get_infographic_topic_input) {
    getInfographicTopic(json: $json) {
      id
      name
      error {
        status_code
        message
      }
    }
  }
`;

export const get_all_infographic_topics = gql`
  query getInfographicTopics($json: get_all_infographic_topic_input) {
    getInfographicTopics(json: $json) {
      items {
        id
        name
        error {
          status_code
          message
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
