import { gql } from "@apollo/client";

export const get_all_candidates_query = gql`
  query getAllUsers($page_number: Float, $page_limit: Float) {
    get_all_users: getAllUsers(
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        name
        mobile_number
        system_user {
          email
        }
      }

      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
