import React, { useEffect, useState } from "react";
import { Box, Button, HStack, Text, VStack } from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { showToast } from "@helpers/toast";
// import ActionButton from "@views/components/ui/table/action_button";
import AntdModal from "@views/components/ui/antd_modal";
import ActionButton from "@views/components/ui/dialog/action_button";
import EvaluatorsDetails from "./evaluators_details";
import { showNotification } from "@helpers/notify";
import {
  get_all_evaluators_query,
  get_one_topic_query,
  useDynamicSelector,
  dynamicRequest,
  delete_topic_mutation,
  dynamicClear,
} from "@services/redux";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import Wrapper from "@views/components/ui/wrapper";
import { Pagination, Popconfirm, Table, Typography, Input } from "antd";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";

const TopicList = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [actionItem, setActionItem] = useState({});
  const [currentpage, setCurrent] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [actionType, setActionType] = useState(null);
  const {
    items: get_all_evaluators,
    loading,
    pagination,
  } = useDynamicSelector("get_all_evaluators");

  const { status: createStatus, error: createError } =
    useDynamicSelector("create_evaluator");

  const { Search } = Input;

  const handleAdd = () => {
    setActionType("add");
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleSearch = (search_txt) => {
    dispatch(
      dynamicRequest(keys, get_all_candidates_query, {
        search_string: search_txt,
      })
    );
  };

  const onShowSizeChange = (current, value) => {
    let keys = [{ key: "get_all_evaluators", loading: true }];
    let query = get_all_evaluators_query;
    let variables = {
      page_number: current,
      page_limit: value,
    };
    dispatch(dynamicRequest(keys, query, variables));
    setPageLimit(value);
  };

  const getAllEvaluators = () => {
    let keys = [{ key: "get_all_evaluators", loading: true }];
    let query = get_all_evaluators_query;
    let variables = {
      page_number: currentpage,
      page_limit: pageLimit,
    };
    dispatch(dynamicRequest(keys, query, variables));
  };

  const fetchData = (value) => {
    let keys = [{ key: "get_all_evaluators", loading: true }];
    let query = get_all_evaluators_query;
    let variables = {
      page_number: value,
      page_limit: pageLimit,
    };
    dispatch(dynamicRequest(keys, query, variables));
    setCurrent(value);
  };

  useEffect(() => {
    if (createError) {
      showToast({
        type: "Failure",
        message: createError,
      });
    }
    if (createStatus === "success") {
      showToast({
        type: "Success",
        message: "Evaluator Created Successfully",
      });
      handleModalClose();
      dispatch(dynamicClear("create_evaluator"));
      getAllEvaluators();
    }
  }, [createStatus]);

  useEffect(() => {
    getAllEvaluators();
  }, []);

  const formatEvaluaterEmail = (value) => {
    return value?.system_user?.email;
  };

  let columns = [
    {
      title: t("table:Name"),
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("Email Id"),
      dataIndex: "",
      key: "email",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return formatEvaluaterEmail(record);
      },
    },

    // {
    //   title: t("table:actions"),
    //   dataIndex: "",
    //   key: "actions",
    //   type: "actions",
    //   align: "center",
    //   render: (record) => {
    //     return (
    //       <span>
    //         <Typography.Link
    //           onClick={() => handleEdit(record)}
    //           style={{ marginRight: 15 }}
    //         >
    //           <AiOutlineEdit />
    //         </Typography.Link>
    //         <Popconfirm
    //           title="Sure to delete?"
    //           onConfirm={() => handleDelete(record)}
    //         >
    //           <AiOutlineDelete color="red" />
    //         </Popconfirm>
    //       </span>
    //     );
    //   },
    // },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("Add Evaluator"),
      colorScheme: "danger.700",
      variant: "outline",
      onPress: handleAdd,
    },
  ];

  return (
    <>
      <Box top="10px" zIndex="2" bg="white">
        <Text fontSize="md" fontWeight="bold">
          {"Evaluator Details"}
        </Text>
      </Box>
      <Box width="100%" mt="10px" marginBottom="20px">
        <VStack space={5}>
          <HStack space={3} justifyContent="flex-end" m={3}>
            {/* <Box marginTop={"10px"}>
              <Search placeholder="search" allowClear onSearch={handleSearch} />
            </Box> */}
            {header_actions.map((headerAction, index) => (
              <Box key={`header-action-${index}`}>
                <ActionButton {...headerAction} />
              </Box>
            ))}
          </HStack>
          <Wrapper>
            <Table
              columns={columns}
              dataSource={get_all_evaluators}
              pagination={false}
              loading={loading}
            />
          </Wrapper>
          <Pagination
            total={pagination?.total_count}
            onShowSizeChange={onShowSizeChange}
            showSizeChanger={true}
            current={currentpage}
            hideOnSinglePage={false}
            onChange={fetchData}
          />
        </VStack>
      </Box>
      <AntdModal
        isOpen={modalOpen}
        onClose={handleModalClose}
        header={
          actionType === "add" ? t("Add Evaluator") : t("Update Evaluator")
        }
        component={
          <EvaluatorsDetails
            label={actionType === "add" ? t("add") : t("update")}
            close={handleModalClose}
            initialValues={actionItem}
          />
        }
      />
    </>
  );
};
export default TopicList;
