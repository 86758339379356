import { v4 as uuidv4 } from "uuid";
import { gql } from "@apollo/client";
import axios from "axios";
import { QueryRequest } from "@services/apollo/api_service";

export async function uploadFiles(file) {
  let url = await getURL(uuidv4());
  let final_url = url?.split("?")[0];
  await axios
    .put(url, file, {
      headers: { "Content-Type": file?.type },
    })
    .then(async (res) => {
      if (res.status.toString() === "200") {
        // return final_url;
      } else {
        success = false;
      }
    });

  return final_url;

  //To Upload in root folder
}

async function getURL(file_name) {
  let query = gql`
    query getUploadUrl(
      $type: String
      $question_paper_id: String
      $file_name: String
      $user_id: String
    ) {
      getUploadUrl(
        type: $type
        question_paper_id: $question_paper_id
        file_name: $file_name
        user_id: $user_id
      ) {
        status
        url
        error {
          status_code
          message
        }
      }
    }
  `;

  let { data } = await QueryRequest(query, {
    type: "photo",
    file_name,
  });
  return data.getUploadUrl.url;
}
