import React, { useEffect, useState, ReactSelect } from "react";
import { Box, HStack, Text, VStack } from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { showToast } from "@helpers/toast";
import AntdModal from "@views/components/ui/antd_modal";
import VForm from "@views/components/ui/antd_form";
import {
  get_all_responses_query,
  dynamicRequest,
  useDynamicSelector,
  get_all_evaluators_query,
  dynamicClear,
} from "@services/redux";
import Wrapper from "@views/components/ui/wrapper";
import {
  Pagination,
  Table,
  Typography,
  Input,
  Button,
  Col,
  Select,
  Form,
  Row,
} from "antd";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { MdOutlineAssignmentTurnedIn } from "react-icons/md";
import EvaluatorAssignForm from "../responses/evaluator_assign_form";
import moment from "moment";

const TopicList = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [form] = Form.useForm();
  const history = useHistory();
  const [modelVisible, setModelVisible] = useState(false);
  const [current, setCurrent] = useState(1);
  const [selected_status, setSelectedStatus] = useState(status);
  const { t } = useTranslation();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [actionType, setActionType] = useState(null);
  const [selected_evaluator, setSelectedEvaluator] = useState();
  const {
    items: get_all_responses,
    loading,
    total_count,
    pagination,
  } = useDynamicSelector("get_all_responses");
  const { Search } = Input;

  const { items: evaluators } = useDynamicSelector("get_all_evaluators");

  useEffect(() => {
    let keys = [{ key: "get_all_responses", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_responses_query, {
        page_number: current,
        page_limit: 10,
        question_paper_id: id,
      })
    );
    let evaluator_keys = [{ key: "get_all_evaluators", loading: true }];
    dispatch(dynamicRequest(evaluator_keys, get_all_evaluators_query, {}));
  }, []);

  const handleModalClose = () => {
    setModelVisible(false);
  };

  const handleSearch = (search_txt) => {
    dispatch(
      dynamicRequest(keys, get_all_candidates_query, {
        search_string: search_txt,
      })
    );
  };
  const handleAdd = () => {
    setModelVisible(true);
  };
  const fetchData = (value) => {
    let keys = [{ key: "get_all_responses", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_responses_query, {
        page_number: value,
        page_limit: 10,
        question_paper_id: id,
        status: selected_status,
      })
    );

    setCurrent(value);
  };

  const assignEvaluator = (item) => {
    setSelectedRowKeys([item?.id]);
    setModelVisible(true);
  };
  const onShowSizeChange = (current, value) => {
    let keys = [{ key: "get_all_responses", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_responses_query, {
        page_number: current,
        page_limit: value,
        question_paper_id: id,
      })
    );
  };

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
    let keys = [{ key: "get_all_responses", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_responses_query, {
        page_number: 1,
        page_limit: 10,
        status: status,
        evaluator_id: selected_evaluator,
        question_paper_id: id,
      })
    );
  };

  const handleEvaluatorChange = (evaluator) => {
    setSelectedEvaluator(evaluator);
    let keys = [{ key: "get_all_responses", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_responses_query, {
        page_number: 1,
        page_limit: 10,
        status: selected_status,
        evaluator_id: evaluator,
        question_paper_id: id,
      })
    );
  };

  const formatResponsesName = (value) => {
    return value?.user?.name;
  };
  const formatResponsesEvaluator = (value) => {
    return value?.evaluator?.name;
  };
  const formatResponsesEmail = (value) => {
    return value?.user?.email;
  };

  const handleStatus = (status) => {
    switch (status) {
      case "evaluation_started":
        return "Evaluation Started";
      case "evaluated":
        return "Evaluated";
      case "downloaded":
        return "Downloaded";
      case "submitted":
        return "Submitted";
      case "evaluator_assigned":
        return "Evaluator Assigned";

      default:
        break;
    }
  };

  let columns = [
    {
      title: t("table:Name"),
      dataIndex: "",
      key: "name",
      render: (record) => {
        return formatResponsesName(record);
      },
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("Evaluator"),
      dataIndex: "",
      key: "name",
      render: (record) => {
        return formatResponsesEvaluator(record);
      },
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("Status"),
      dataIndex: "status",
      key: "status",
      sortable: false,
      align: "left",
      flex: 1,
      render: (status) => handleStatus(status),
    },
    {
      title: t("Submitted Date"),
      dataIndex: "uploaded_at",
      key: "status",
      sortable: false,
      align: "left",
      flex: 1,
      render: (date) =>
        date ? moment(new Date(date)).format("DD-MM-YYYY, h:mm a") : "",
    },
    {
      title: t("Corrected Date"),
      dataIndex: "evaluated_at",
      key: "status",
      sortable: false,
      align: "left",
      flex: 1,
      render: (date) =>
        date ? moment(new Date(date)).format("DD-MM-YYYY, h:mm a") : "",
    },
    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "center",
      render: (record) => {
        return (
          <span>
            <Typography.Link
              onClick={() => assignEvaluator(record)}
              style={{ marginRight: 15 }}
            >
              <MdOutlineAssignmentTurnedIn />
            </Typography.Link>
          </span>
        );
      },
    },
  ];
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange,
  // };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled:
        record.status === "evaluation_started" || record.status === "evaluated",
    }),
  };

  return (
    <>
      <Box top="10px" zIndex="2" bg="white">
        <Text fontSize="md" fontWeight="bold">
          {location.state.title + " " + "Response"}
        </Text>
      </Box>
      <HStack space={3} justifyContent="flex-end" m={3}>
        {/* <Box marginTop={"5px"}>
          <Search placeholder="search" allowClear onSearch={handleSearch} />
        </Box> */}
      </HStack>
      <Box width="100%" marginBottom="20px">
        <HStack padding="10px" space="5">
          <Box bg="white" flex={1}>
            <Text bold>Status</Text>
            <Form.Item>
              <Select
                defaultValue={selected_status}
                onChange={handleStatusChange}
                allowClear={true}
                options={[
                  {
                    value: "downloaded",
                    label: "Downloaded",
                  },
                  {
                    value: "submitted",
                    label: "Submitted",
                  },
                  { value: "evaluator_assigned", label: "Evaluator Assigned" },
                  {
                    value: "evaluation_started",
                    label: "Evaluation Started",
                  },
                  {
                    value: "evaluated",
                    label: "Evaluated",
                  },
                ]}
              />
            </Form.Item>
          </Box>
          <Box flex={1}>
            <Text bold>Evaluators</Text>
            <Form.Item>
              <Select
                defaultValue={selected_evaluator}
                onChange={handleEvaluatorChange}
                allowClear={true}
                // options={evaluators}
                options={evaluators?.map((x) => {
                  return { value: x.id, label: x.name };
                })}
              />
            </Form.Item>
          </Box>
          <Box alignSelf={"center"} marginBottom="3px">
            <Button
              disabled={!selectedRowKeys.length}
              type="primary"
              onClick={handleAdd}
            >
              Assign Evaluators
            </Button>
          </Box>
        </HStack>
        <VStack space={5}>
          <Wrapper>
            <Table
              rowKey={"id"}
              columns={columns}
              dataSource={get_all_responses}
              pagination={false}
              loading={loading}
              rowSelection={rowSelection}
            />
          </Wrapper>
          <Pagination
            total={pagination?.total_count}
            showSizeChanger={true}
            current={current}
            onShowSizeChange={onShowSizeChange}
            hideOnSinglePage={false}
            onChange={fetchData}
          />
          <AntdModal
            id="evaluaterAssign"
            isOpen={modelVisible}
            onClose={handleModalClose}
            width={500}
            noscroll={true}
            header={t("Assign Evaluators For")}
            component={
              <EvaluatorAssignForm
                label={t("add")}
                close={handleModalClose}
                selectedRowKeys={selectedRowKeys}
              />
            }
            // source={actionItem}
            size={"container"}
          />
        </VStack>
      </Box>
    </>
  );
};
export default TopicList;
