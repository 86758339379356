import React, { useEffect } from "react";
import VForm from "@views/components/ui/antd_form";
import { Form, Button } from "antd";
import { useDispatch } from "react-redux";
import {
  dynamicRequest,
  create_board_mutation,
  update_board_mutation,
  useDynamicSelector,
} from "@services/redux";
import { useTranslation } from "react-i18next";
import { HStack } from "native-base";

const BoardDetails = (props) => {
  const { t } = useTranslation();
  const { initialValues } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { loading: createLoading } = useDynamicSelector("create_board");
  const { loading: updateLoading } = useDynamicSelector("update_board");
  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  const handleSubmit = (values) => {
    delete values.topics;
    if (initialValues?.id) {
      let variables = {
        id: initialValues?.id,
        data: {
          ...values,
          icon: values?.icon?.[0]?.url,
        },
      };
      let keys = [{ key: "update_board", loading: true }];
      dispatch(dynamicRequest(keys, update_board_mutation, variables, "M"));
    } else {
      let variables = {
        data: {
          ...values,
          icon: values?.icon?.[0]?.url,
        },
      };
      let keys = [{ key: "create_board", loading: true }];
      dispatch(dynamicRequest(keys, create_board_mutation, variables, "M"));
    }
  };

  const handleChange = (a, b) => {};

  return (
    <Form
      form={form}
      name={"board_details"}
      layout={"vertical"}
      onFinish={handleSubmit}
      onValuesChange={handleChange}
      className="user_form ant-select-selector ant-select-selection-search"
    >
      <VForm.TextBox
        label={t("Name")}
        field={"name"}
        rules={[
          {
            required: true,
            message: "Name is Required",
          },
        ]}
      />
      <VForm.File
        label={t("Icon")}
        field={"icon"}
        rules={[
          {
            required: true,
            message: "Icon is Required",
          },
        ]}
      />

      <VForm.Number
        label={t("Sort Order")}
        field={"sort_order"}
        rules={[
          {
            required: true,
            message: "Sort Order is Required",
          },
        ]}
      />
      <HStack space="10" justifyContent={"flex-end"} marginRight="40px">
        <VForm.Button
          loading={initialValues?.id ? updateLoading : createLoading}
          disabled={false}
        >
          {t("Submit")}
        </VForm.Button>
        <Button onClick={props.close}>{t("close")}</Button>
      </HStack>
    </Form>
  );
};
export default BoardDetails;
