import {
  Route,
  Link as ReactLink,
  BrowserRouter,
  Redirect,
} from "react-router-dom";
import React, { useState } from "react";

import {
  HStack,
  VStack,
  Text,
  Image,
  Center,
  Hidden,
  Stack,
  Box,
} from "native-base";
import aimlogo from "@assets/icons/aim_logo.png";
import LoginForm from "./login_form";
import { useEffect } from "react";
import ForgotPassword from "./forgot_password_form";
import ForgotPasswordForm from "./forgot_password_form";
import { useTranslation } from "react-i18next";
import logo from "@assets/icons/nokkam.png";

const Login = (props) => {
  const { t } = useTranslation();

  const [_showForgotPasswordForm, setShowForgotPasswordForm] = useState(false);
  const [_showLoginForm, setShowLoginForm] = useState(true);

  const showForgotPasswordForm = (value1) => {
    setShowForgotPasswordForm(value1);
  };

  const showLoginForm = (value3) => {
    setShowLoginForm(value3);
  };

  return (
    <>
      <Box
        safeAreaTop
        _light={{
          bg: "primary.900",
        }}
        _dark={{
          bg: "coolGray.900",
        }}
      />
      <Center
        _light={{
          bgImage: `url(${logo})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          opacity: "1",
          height: "100%",
        }}
      >
        <Stack
          flexDirection={{
            base: "column",
            md: "row",
          }}
          w="100%"
          maxW={{
            md: "1016px",
          }}
          flex={{
            base: "1",
            md: "none",
          }}
        >
          <Hidden from="md">
            <VStack px="4" mt="4" mb="5" space="9">
              <HStack alignItems="center">
                <Image
                  cursor={"pointer"}
                  px={2}
                  py={2}
                  size="80px"
                  alt="logo"
                  resizeMode={"contain"}
                  source={aimlogo}
                />

                <Text
                  textAlign={"center"}
                  fontSize={{ base: 30, xs: 20, sm: 20, md: 25 }}
                  fontWeight="bold"
                  color="#396591"
                  marginLeft="10px"
                >
                  {t("AASC_mission")}
                </Text>
              </HStack>
            </VStack>
          </Hidden>
          <Hidden till="md">
            <Center
              flex="1"
              bg="#134475e6"
              borderTopLeftRadius={{
                base: "0",
                md: "xl",
              }}
              borderBottomLeftRadius={{
                base: "0",
                md: "xl",
              }}
            >
              <Image
                h="24"
                size="80"
                alt="NativeBase Startup+ "
                resizeMode={"contain"}
                source={aimlogo}
              />
              {/* <Box>
                <img
                height="70px"
                width="100%"
                  alt="NativeBase Startup+ "
                  resizeMode={"contain"}
                  src={
                    "https://aasc-assets-dev.s3.ap-south-1.amazonaws.com/web-assets/nokkam.png"
                  }
                />
              </Box> */}

              {/* <Text bold fontSize={30} color="white" py={5}>
                AASC Mission 60
              </Text> */}
            </Center>
          </Hidden>
          {_showLoginForm && !_showForgotPasswordForm && (
            <LoginForm
              props={props}
              showForgotPasswordForm={showForgotPasswordForm}
              showLoginForm={showLoginForm}
            />
          )}
          {_showForgotPasswordForm && !_showLoginForm && (
            <ForgotPasswordForm
              showForgotPasswordForm={showForgotPasswordForm}
              showLoginForm={showLoginForm}
            />
          )}
        </Stack>
      </Center>
    </>
  );
};

export default Login;
