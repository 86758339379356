import { gql } from "@apollo/client";

export const create_evaluator_mutation = gql`
  mutation ($data: register_admin_user_input) {
    create_evaluator: registerUserByAdmin(data: $data) {
      status
      user_id
      name
      error {
        status_code
        message
      }
    }
  }
`;

export const get_all_evaluators_query = gql`
  query getAllEvaluators($page_number: Float, $page_limit: Float) {
    get_all_evaluators: getAllEvaluators(
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        name
        mobile_number
        system_user {
          email
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const set_evaluator_for_question_paper_mutation = gql`
  mutation setEvaluatorForQuestionPaper($data: set_evaluator) {
    set_evaluator_for_questionPaper: setEvaluatorForQuestionPaper(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const assign_evaluator_mutation = gql`
  mutation assignEvaluator($data: assign_evaluator) {
    assign_evaluator: assignEvaluator(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
