import { gql } from "@apollo/client";

export const create_text_book_subject_mutation = gql`
  mutation createTextBookSubject($data: text_book_subject_input) {
    create_text_book_subject: createTextBookSubject(data: $data) {
      id
      status
      count
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_text_book_subject_mutation = gql`
  mutation deleteTextBookSubject($id: String!) {
    delete_text_book_subject: deleteTextBookSubject(id: $id) {
      id
      status
      count
      error {
        status_code
        message
      }
    }
  }
`;

export const update_text_book_subject_mutation = gql`
  mutation updateTextBookSubject($id: String!, $data: text_book_subject_input) {
    update_text_book_subject: updateTextBookSubject(id: $id, data: $data) {
      id
      status
      count
      error {
        status_code
        message
      }
    }
  }
`;

export const get_all_text_book_subject_query = gql`
  query getAllTextBookSubjects(
    $text_book_class_id: String
    $page_number: Float
    $page_limit: Float
  ) {
    get_all_text_book_subject: getAllTextBookSubjects(
      text_book_class_id: $text_book_class_id
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        name
        text_book_class_id
        text_book_url
        subject_note_view_count
        subject_note_download_count
        text_book_class {
          id
          name
          icon
          sort_order
        }
      }
      pagination {
        page_limit
        page_number
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const get_one_text_book_subject_query = gql`
  query getTextBookSubject($id: String!) {
    get_one_text_book_subject: getTextBookSubject(id: $id) {
      id
      name
      text_book_class_id
      icon
      sort_order
      text_book_class {
        id
        name
        icon
        sort_order
      }
      error {
        status_code
        message
      }
    }
  }
`;
