import { gql } from "@apollo/client";

export const create_topic_mutation = gql`
  mutation createExamTopic($data: exam_topic_input) {
    create_topic: createExamTopic(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_topic_mutation = gql`
  mutation updateExamTopic($data: exam_topic_input, $id: String!) {
    update_topic: updateExamTopic(data: $data, id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_topic_mutation = gql`
  mutation deleteExamTopic($id: String!) {
    delete_topic: deleteExamTopic(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_all_topics_query = gql`
  query getAllExamTopic(
    $page_number: Float
    $page_limit: Float
    $exam_id: String
  ) {
    get_all_topics: getAllExamTopics(
      page_number: $page_number
      page_limit: $page_limit
      exam_id: $exam_id
    ) {
      items {
        id
        name
        sort_order
        error {
          status_code
          message
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_all_for_exam_notes_topics_query = gql`
  query getAllExamTopic(
    $page_number: Float
    $page_limit: Float
    $exam_id: String
  ) {
    get_all_topics_for_exam_notes: getAllExamTopics(
      page_number: $page_number
      page_limit: $page_limit
      exam_id: $exam_id
    ) {
      items {
        id
        name
        sort_order
        error {
          status_code
          message
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_one_topic_query = gql`
  query getExamTopic($id: String) {
    get_topic: getExamTopic(id: $id) {
      id
      name
      error {
        status_code
        message
      }
    }
  }
`;
