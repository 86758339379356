import React, { useEffect, useState } from "react";
import { Box, Hidden, HStack, useBreakpointValue, VStack } from "native-base";
import { ReactSketchCanvas } from "react-sketch-canvas";
import loadImage from "@helpers/load_image";
import { Button, Form, Tabs } from "antd";
import { MdOutlineRedo, MdOutlineUndo } from "react-icons/md";
import { BsEraser } from "react-icons/bs";
import { BiPencil } from "react-icons/bi";
import { GrLinkNext } from "react-icons/gr";
import { TbHandFinger } from "react-icons/tb";
import { IoMdRefresh } from "react-icons/io";
import AntdModal from "@views/components/ui/antd_modal";
import { RiCursorLine } from "react-icons/ri";
import fileUpload from "@helpers/file_upload";

const ImageRenderer = (props) => {
  const {
    current_page,
    canvas,
    submission,
    onChange,
    sections,
    current_tab,
    jump,
    setJump,
  } = props;
  const [ratio, setRatio] = useState({});
  const [is_pen, setIsPen] = useState(false);
  const [is_mouse, setIsMouse] = useState(false);
  const [is_hand, setIsHand] = useState(false);
  const [isErasing, setIsErasing] = useState(false);

  useEffect(() => {
    if (submission?.question_paper?.id)
      loadImage(
        setRatio,
        `https://aasc-assets-dev.s3.ap-south-1.amazonaws.com/submissions/${submission?.question_paper?.id}/${submission?.user?.id}/page_${current_page}.jpg`
      );
  }, [submission, current_page]);
  const save_page = () => {
    let file_name = current_page;
    if (canvas?.current?.exportSvg)
      canvas?.current
        ?.exportSvg()
        .then(async (data) => {
          data = data.replace(
            `xlink:href=" "`,
            `xlink:href="https://aasc-assets-dev.s3.ap-south-1.amazonaws.com/submissions/${submission?.question_paper?.id}/${submission?.user?.id}/page_${current_page}.jpg"`
          );

          let url = await fileUpload(
            data,
            file_name,
            "correction",
            submission?.question_paper?.id,
            submission?.user?.id
          );
        })
        .catch((e) => {});
  };
  const width = useBreakpointValue({
    base: 100,
    xs: 360,
    sm: 360,
    md: 750,
    lg: 530,
  });
  const write_mode = useBreakpointValue({
    base: false,
    xs: "mobile",
    sm: "mobile",
    md: "tab",
    lg: "lap",
  });
  let url1 = `https://aasc-assets-dev.s3.ap-south-1.amazonaws.com/submissions/${submission?.question_paper?.id}/${submission?.user?.id}/page_${current_page}.jpg`;
  let url2 = `https://aasc-assets-dev.s3.ap-south-1.amazonaws.com/submissions/${submission?.question_paper?.id}/${submission?.user?.id}/corrected/${current_page}.jpg`;
  return (
    <>
      <HStack>
        <ReactSketchCanvas
          ref={canvas}
          canvasColor="#ffffff00"
          style={{
            backgroundImage: `url(${url2}),url(${url1})`,
            width: width + "px",
            height: width / ratio + "px",
            objectFit: "contain",
            cursor: "crosshair",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            border: "0.0625rem solid #9c9c9c",
            borderRadius: "0.25rem",
          }}
          strokeWidth={2}
          strokeColor="red"
          allowOnlyPointerType={
            is_pen ? "pen" : is_hand ? "touch" : is_mouse ? "mouse" : ""
          }
          backgroundImage={` `}
          exportWithBackgroundImage={true}
        />
        {is_pen || is_hand || is_mouse || isErasing ? (
          ""
        ) : (
          <Box
            ref={canvas}
            style={{
              marginLeft: "-" + width + "px",
              width: width + "px",
              height: width / ratio + "px",
              opacity: 1,
            }}
          />
        )}
        <VStack space={2} mt={6} mx={"auto"}>
          <Button
            onClick={() => {
              canvas.current.undo();
            }}
            icon={<MdOutlineUndo />}
          ></Button>
          <Button
            onClick={() => {
              canvas.current.redo();
            }}
            icon={<MdOutlineRedo />}
          ></Button>
          <Button
            type={isErasing ? "primary" : ""}
            onClick={() => {
              setIsErasing(!isErasing);
              canvas.current.eraseMode(true);
            }}
            icon={<BsEraser />}
          ></Button>
          {write_mode === "tab" || write_mode === "mobile" ? (
            <Button
              type={is_hand ? "primary" : ""}
              onClick={() => {
                if (isErasing) canvas?.current?.eraseMode(false);
                if (is_hand) {
                  save_page();
                }
                setIsErasing(false);
                setIsPen(false);
                setIsMouse(false);
                setIsHand(!is_hand);
              }}
              icon={<TbHandFinger />}
            ></Button>
          ) : (
            ""
          )}
          {write_mode === "lap" ? (
            <Button
              type={is_mouse ? "primary" : ""}
              onClick={() => {
                if (isErasing) canvas?.current?.eraseMode(false);
                if (is_mouse) {
                  save_page();
                }
                setIsErasing(false);
                setIsPen(false);
                setIsHand(false);
                setIsMouse(!is_mouse);
              }}
              icon={<RiCursorLine />}
            ></Button>
          ) : (
            ""
          )}
          {write_mode === "tab" ? (
            <Button
              type={is_pen ? "primary" : ""}
              onClick={() => {
                if (isErasing) canvas?.current?.eraseMode(false);
                if (is_mouse) {
                  save_page();
                }
                setIsErasing(false);
                setIsHand(false);
                setIsMouse(false);
                setIsPen(!is_pen);
              }}
              icon={<BiPencil />}
            ></Button>
          ) : (
            ""
          )}
          <Form.Item style={{ width: "100%" }}>
            <Button
              style={{ width: "100%" }}
              htmlType="submit"
              icon={<GrLinkNext />}
            ></Button>
          </Form.Item>
          <Hidden from="lg">
            <Button
              icon={<IoMdRefresh />}
              onClick={() => {
                setJump(!jump);
              }}
            ></Button>
          </Hidden>
        </VStack>
      </HStack>
      <AntdModal
        isOpen={jump}
        onClose={() => {
          setJump(false);
        }}
        width={450}
        header={"Jump to"}
        component={
          <>
            <Tabs
              defaultActiveKey="1"
              onChange={(key) => {
                onChange(key);
              }}
              items={sections}
              activeKey={current_tab}
            />
            <HStack justifyContent={"flex-end"}>
              <Button onClick={() => setJump(false)}>Close</Button>
            </HStack>
          </>
        }
      />
    </>
  );
};
export default ImageRenderer;
