import { gql } from "@apollo/client";

export const get_all_exam_play_list_query = gql`
  query getAllExamPlaylists($page_limit: Float, $exam_id: String!) {
    get_all_exam_play_list: getAllExamPlaylists(
      page_limit: $page_limit
      exam_id: $exam_id
    ) {
      items {
        id
        playlist_url
        name
        exam_id
        sort_order
      }
    }
  }
`;

export const get_one_exam_play_list_query = gql`
  query getExamPlaylist($id: String!) {
    get_one_exam_play_list: getExamPlaylist(id: $id) {
      id
      name
      exam_id
      playlist_url
      sort_order
    }
  }
`;
export const create_exam_play_list_mutation = gql`
  mutation createExamPlaylist($data: exam_playlist_input) {
    create_exam_play_list: createExamPlaylist(data: $data) {
      id
      status
      count
      error {
        status_code
        message
      }
    }
  }
`;
export const update_exam_play_list_mutation = gql`
  mutation updateExamPlaylist($data: exam_playlist_input, $id: String!) {
    update_exam_play_list: updateExamPlaylist(data: $data, id: $id) {
      id
      status
      count
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_exam_play_list_mutation = gql`
  mutation deleteExamPlaylist($id: String!) {
    delete_exam_play_list: deleteExamPlaylist(id: $id) {
      id
      status
      count
      error {
        status_code
        message
      }
    }
  }
`;

export const play_list_videos_query = gql`
  query getAllExamPlaylistVideos(
    $exam_playlist_id: String!
    $page_number: Float
    $page_limit: Float
  ) {
    play_list_videos: getAllExamPlaylistVideos(
      exam_playlist_id: $exam_playlist_id
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        name
        video_url
        video_view_count
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
