import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import "./index.css";
import { Provider } from "react-redux";
import {
  Box,
  NativeBaseProvider,
  extendTheme,
  theme as nbTheme,
  Button,
} from "native-base";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IconContext } from "react-icons";
import { store } from "@services/redux/store";
import App from "./App";
import "./i18n";
import { Toaster } from "react-hot-toast";

const theme = extendTheme({
  colors: {
    primary: nbTheme.colors.violet,
    primaryContent: nbTheme.colors.warmGray,
    inputBorder: nbTheme.colors.coolGray,
    error: nbTheme.colors.red,
  },
  breakpoints: {
    base: 0,
    xs: 320,
    sm: 480,
    md: 768,
    lg: 1024,
    xl: 1440,
    "2xl": 2560,
    "3xl": 3840,
  },
  components: {
    Box: {
      variants: {
        text_book_board_heading_box: () => {
          return {
            position: "sticky",
            top: "10px",
            zIndex: "2",
            bg: "white"
          }
        },
        text_book_board_top_box: () => {
          return {
            width: "100%",
            mt: "10px",
            marginBottom: "20px"
          }
        },
      }
    },
    Text: {
      variants: {
        text_book_board_heading_text: () => {
          return {
            fontSize: "md",
            fontWeight: "bold"
          }
        },
      }
    },
    VStack: {
      variants: {
        text_book_board_top_vstack: () => {
          return {
            space: "5"
          }
        },

      }
    },
    HStack: {
      variants: {
        add_text_book_board_button_hstack: () => {
          return {
            space: "3",
            justifyContent: "flex-end",
            m: "5"
          }
        },
        submit_hstack: () => {
          return {
            space: "10",
            justifyContent: "flex-end",
            marginRight: "40px"
          }
        }
      }
    },
  }
});
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <NativeBaseProvider theme={theme}>
      <IconContext.Provider value={{ className: "global-class-name" }}>
        <App />
        <ToastContainer newestOnTop pauseOnFocusLoss />
        <Toaster position="top-center" reverseOrder={false} />
      </IconContext.Provider>
    </NativeBaseProvider>
  </Provider>
);
