import React, { useEffect, useState } from "react";
import { Box, Button, HStack, Text, VStack, Pressable } from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import AntdModal from "@views/components/ui/antd_modal";
import { showNotification } from "@helpers/notify";
import {
  useDynamicSelector,
  dynamicRequest,
  dynamicClear,
  delete_text_book_class_mutation,
  get_all_text_book_class_query,
  get_one_text_book_board_query,
} from "@services/redux";
import { AiOutlineEdit, AiOutlineDelete, AiFillYoutube } from "react-icons/ai";
import Wrapper from "@views/components/ui/wrapper";
import { Pagination, Popconfirm, Table, Typography } from "antd";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ClassDetails from "./class_details";

const ClassList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [modalOpen, setModalOpen] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [current_limit, setCurrentLimit] = useState(10);
  const [actionItem, setActionItem] = useState({});
  const [current, setCurrent] = useState(1);
  const { board_id } = useParams();
  const { state } = useLocation();

  const {
    items: class_items,
    pagination,
    loading: class_loading,
  } = useDynamicSelector("get_all_text_book_class");
  const { status: createStatus, error: createError } = useDynamicSelector(
    "create_text_book_class"
  );
  const { status: updateStatus, error: updateError } = useDynamicSelector(
    "update_text_book_class"
  );
  const { status: deleteStatus, error: deleteError } = useDynamicSelector(
    "delete_text_book_class"
  );

  const handleModalClose = () => {
    setModalOpen(false);
  };
  const handleAdd = () => {
    setActionItem({});
    setActionType("add");
    setModalOpen(true);
  };

  const handleEdit = (item) => {
    let temp = { ...item };

    if (item.icon)
      temp.icon = [
        {
          uid: "-1",
          name: "Icon",
          status: "done",
          url: item?.icon,
        },
      ];
    setActionItem(temp);
    setActionType("edit");
    setModalOpen(true);
  };

  const handleDelete = (record) => {
    let keys = [{ key: "delete_text_book_class", loading: true }];
    dispatch(
      dynamicRequest(keys, delete_text_book_class_mutation, {
        id: record?.id,
      })
    );
  };

  useEffect(() => {
    let keys = [{ key: "get_all_text_book_class", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_text_book_class_query, {
        page_number: current,
        page_limit: current_limit,
        text_book_board_id: board_id,
      })
    );
    keys = [{ key: "get_one_text_book_board", loading: true }];
    dispatch(
      dynamicRequest(keys, get_one_text_book_board_query, {
        id: board_id,
      })
    );
  }, []);

  useEffect(() => {
    if (actionType === "add" && createStatus === "success") {
      handleModalClose();
      showNotification({
        type: "success",
        message: `${t("text_book_class_create_success_msg")}`,
      });
      dispatch(dynamicClear("create_text_book_class"));

      let keys = [{ key: "get_all_text_book_class", loading: true }];
      dispatch(
        dynamicRequest(keys, get_all_text_book_class_query, {
          page_number: current,
          page_limit: current_limit,
          text_book_board_id: board_id,
        })
      );
    } else if (actionType === "edit" && updateStatus === "success") {
      handleModalClose();

      showNotification({
        type: "success",
        message: `${t("text_book_class_update_success_msg")}`,
      });
      dispatch(dynamicClear("update_text_book_class"));

      let keys = [{ key: "get_all_text_book_class", loading: true }];

      dispatch(
        dynamicRequest(keys, get_all_text_book_class_query, {
          page_number: current,
          page_limit: current_limit,
          text_book_board_id: board_id,
        })
      );
    } else if (deleteStatus === "success") {
      showNotification({
        type: "success",
        message: `${t("text_book_class_delete_success_msg")}`,
      });
      dispatch(dynamicClear("delete_text_book_class"));

      let keys = [{ key: "get_all_text_book_class", loading: true }];

      dispatch(
        dynamicRequest(keys, get_all_text_book_class_query, {
          page_number: current,
          page_limit: current_limit,
          text_book_board_id: board_id,
        })
      );
    } else if (actionType === "add" && createStatus === "failure") {
      handleModalClose();
      showNotification({
        type: "failure",
        message: `${t("text_book_class_create_failure_msg")}`,
      });
      dispatch(dynamicClear("create_text_book_class"));

      let keys = [{ key: "get_all_text_book_class", loading: true }];
      dispatch(
        dynamicRequest(keys, get_all_text_book_class_query, {
          page_number: current,
          page_limit: current_limit,
          text_book_board_id: board_id,
        })
      );
    } else if (actionType === "edit" && updateStatus === "failure") {
      handleModalClose();

      showNotification({
        type: "failure",
        message: `${t("text_book_class_update_failure_msg")}`,
      });
      dispatch(dynamicClear("update_text_book_class"));

      let keys = [{ key: "get_all_text_book_class", loading: true }];

      dispatch(
        dynamicRequest(keys, get_all_text_book_class_query, {
          page_number: current,
          page_limit: current_limit,
          text_book_board_id: board_id,
        })
      );
    } else if (deleteStatus === "failure") {
      showNotification({
        type: "failure",
        message: `${t("text_book_class_delete_failure_msg")}`,
      });
      dispatch(dynamicClear("delete_text_book_class"));

      let keys = [{ key: "get_all_text_book_class", loading: true }];

      dispatch(
        dynamicRequest(keys, get_all_text_book_class_query, {
          page_number: current,
          page_limit: current_limit,
          text_book_board_id: board_id,
        })
      );
    }
  }, [createStatus, updateStatus, deleteStatus]);

  const fetchData = (page_number, page_limit) => {
    let keys = [{ key: "get_all_text_book_class", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_text_book_class_query, {
        page_number: page_number,
        page_limit: page_limit,
        text_book_board_id: board_id,
      })
    );
    setCurrent(page_number);
    setCurrentLimit(page_limit);
  };

  const handleResponse = (data) => {
    // check the id while passing, to pass board and class id
    history.push({
      pathname: `${ROUTES.SUBJECT_LIST}/${board_id}/${data.id}`,
      state: data,
    });
  };
  const handleBack = () => {
    history.push(ROUTES.TEXT_BOOK);
  };
  let columns = [
    {
      title: t("table:Name"),
      dataIndex: "",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
      render: (data) => (
        <Pressable onPress={() => handleResponse(data)}>
          <Text color={"#0000ff"}>{data.name}</Text>
        </Pressable>
      ),
    },
    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "center",
      render: (record) => {
        return (
          <span>
            <Typography.Link
              onClick={() => handleEdit(record)}
              style={{ marginRight: 15 }}
            >
              <AiOutlineEdit />
              {/* <Text color="blue.400">Edit</Text> */}
            </Typography.Link>
            <Popconfirm
              title={t("sure_to_delete")}
              onConfirm={() => handleDelete(record)}
            >
              <AiOutlineDelete />
              {/* <Text color="red.400">
                  <a>Delete</a>
                </Text> */}
            </Popconfirm>
          </span>
        );
      },
    },
  ];
  return (
    <>
      <Box variant="text_book_board_heading_box">
        <Text variant="text_book_board_heading_text">
          {state?.name} &nbsp;{t("classes")}
        </Text>
      </Box>
      <Box variant="text_book_board_top_box">
        <VStack variant="text_book_board_top_vstack">
          <HStack variant="add_text_book_board_button_hstack">
            <Box key={`header-action`}>
              <Button type="primary" onPress={handleAdd}>
                {t("add_class")}
              </Button>
            </Box>
          </HStack>
          <Wrapper>
            <Table
              loading={class_loading}
              columns={columns}
              dataSource={class_items}
              pagination={false}
            />
          </Wrapper>
          <Pagination
            total={pagination?.total_count}
            showSizeChanger={true}
            current={current}
            hideOnSinglePage={false}
            onChange={fetchData}
            pageSizeOptions={[1, 5, 10, 20, 50]}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} classes`
            }
          />
          <HStack justifyContent={"flex-end"}>
            <Button variant={"outline"} onPress={handleBack}>
              Back
            </Button>
          </HStack>
        </VStack>
      </Box>
      <AntdModal
        isOpen={modalOpen}
        onClose={handleModalClose}
        width={450}
        header={actionType === "add" ? t("add_class") : t("update_class")}
        component={
          <ClassDetails
            label={actionType === "add" ? t("add") : t("update")}
            close={handleModalClose}
            initialValues={actionItem}
            board_id={board_id}
          />
        }
      />
    </>
  );
};

export default ClassList;
