import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, VStack, Text, HStack } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import {
  get_all_note_query,
  useDynamicSelector,
  delete_note_mutation,
  dynamicRequest,
  dynamicClear,
  get_one_topic_query,
} from "@services/redux";
import NoteDetails from "./note_details";
import AntdModal from "@views/components/ui/antd_modal";
import ActionButton from "@views/components/ui/dialog/action_button";
import { Button, Pagination, Popconfirm, Table, Typography } from "antd";
import { showNotification } from "@helpers/notify";
import { useParams } from "react-router-dom";

const NoteList = () => {
  const dispatch = useDispatch();
  const [actionType, setActionType] = useState(null);
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [current, setCurrent] = useState(1);
  const [current_limit, setCurrentLimit] = useState(10);
  const { topic_id } = useParams();

  const { items: get_all_notes, pagination } =
    useDynamicSelector("get_all_notes");
  const { name } = useDynamicSelector("get_topic");
  const { status: createStatus, error: createError } =
    useDynamicSelector("create_note");
  const { status: updateStatus, error: updateError } =
    useDynamicSelector("update_note");

  const {
    loading: deleteLoading,
    status: deleteStatus,
    error: deleteError,
  } = useDynamicSelector("delete_note");

  const handleAdd = () => {
    setActionItem({});
    setActionType("add");
    setModalOpen(true);
  };

  const handleEdit = (item) => {
    setActionItem({
      ...item,
      url: [
        {
          uid: "-1",
          name: "URL",
          status: "done",
          url: item?.url,
        },
      ],
    });
    setActionType("edit");
    setModalOpen(true);
  };

  const handleDelete = (record) => {
    let keys = [{ key: "delete_note", loading: true }];
    dispatch(
      dynamicRequest(keys, delete_note_mutation, {
        id: record?.id,
      })
    );
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    let keys = [{ key: "get_all_notes", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_note_query, {
        page_number: current,
        page_limit: current_limit,
        topic_id,
      })
    );
    keys = [{ key: "get_topic", loading: true }];
    dispatch(
      dynamicRequest(keys, get_one_topic_query, {
        id: topic_id,
      })
    );
  }, []);

  useEffect(() => {
    if (actionType === "add" && createStatus === "success") {
      handleModalClose();
      showNotification({
        type: "success",
        message: `${"Note"} added successfully`,
      });
      let keys = [{ key: "get_all_notes", loading: true }];

      dispatch(
        dynamicRequest(keys, get_all_note_query, {
          page_number: current,
          page_limit: current_limit,
          topic_id,
        })
      );
      dispatch(dynamicClear("create_note"));
    } else if (actionType === "edit" && updateStatus === "success") {
      handleModalClose();

      showNotification({
        type: "success",
      });
      dispatch(dynamicClear("update_note"));

      let keys = [{ key: "get_all_notes", loading: true }];

      dispatch(
        dynamicRequest(keys, get_all_note_query, {
          page_number: current,
          page_limit: current_limit,
          topic_id,
        })
      );
    } else if (deleteStatus === "success") {
      showNotification({
        type: "success",
        message: `${"Note"} deleted successfully`,
      });
      dispatch(dynamicClear("create_note"));

      let keys = [{ key: "get_all_notes", loading: true }];

      dispatch(
        dynamicRequest(keys, get_all_note_query, {
          page_number: current,
          page_limit: current_limit,
          topic_id,
        })
      );
    }
  }, [createStatus, updateStatus, deleteStatus]);

  const fetchData = (page_number, page_limit) => {
    let keys = [{ key: "get_all_notes", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_note_query, {
        page_number,
        page_limit,
        topic_id,
      })
    );
    setCurrent(page_number);
    setCurrentLimit(page_limit);
  };
  let columns = [
    {
      title: t("table:name"),
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:no_of_views"),
      dataIndex: "note_view_count",
      key: "note_download_count",
      sortable: false,
      align: "left",
      width: "150px",
    },
    {
      title: t("table:no_of_downloads"),
      dataIndex: "note_download_count",
      key: "note_download_count",
      sortable: false,
      align: "left",
      width: "200px",
    },
    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "center",
      render: (record) => {
        return (
          <span>
            <Typography.Link
              onClick={() => handleEdit(record)}
              style={{ marginRight: 15 }}
            >
              <AiOutlineEdit />
              {/* <Text color="blue.400">Edit</Text> */}
            </Typography.Link>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record)}
            >
              <AiOutlineDelete />
              {/* <Text color="red.400">
                <a>Delete</a>
              </Text> */}
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("Add Note"),
      colorScheme: "danger.700",
      variant: "outline",
      onPress: handleAdd,
    },
  ];

  return (
    <>
      <Box position="sticky" top="10px" zIndex="2" bg="white">
        <Text fontSize="md" fontWeight="bold">
          {name} {" Notes"}
        </Text>
      </Box>
      <Box width="100%" mt="10px" marginBottom="20px">
        <VStack space={5}>
          <HStack space={3} justifyContent="flex-end" m={3}>
            <Box key={`header-action`}>
              <Button type="primary" onClick={handleAdd}>
                Add Notes
              </Button>
            </Box>
          </HStack>
          <Wrapper>
            <Table
              columns={columns}
              dataSource={get_all_notes}
              pagination={false}
            />
          </Wrapper>
          <Pagination
            total={pagination?.total_count}
            showSizeChanger={true}
            current={current}
            hideOnSinglePage={false}
            onChange={fetchData}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} notes`
            }
          />
        </VStack>
      </Box>

      <AntdModal
        isOpen={modalOpen}
        onClose={handleModalClose}
        width={450}
        header={actionType === "add" ? t("Add Note") : t("Update Note")}
        component={
          <NoteDetails
            label={actionType === "add" ? t("add") : t("update")}
            close={handleModalClose}
            initialValues={actionItem}
            topic_id={topic_id}
          />
        }
      />
    </>
  );
};
export default NoteList;
