import React, { useEffect } from "react";
import VForm from "@views/components/ui/antd_form";
import { Form, Button } from "antd";
import { useDispatch } from "react-redux";
import {
  dynamicRequest,
  create_exam_play_list_mutation,
  update_exam_play_list_mutation,
} from "@services/redux";
import { useTranslation } from "react-i18next";
import { HStack } from "native-base";

const ExamVideoPlayListDetails = (props) => {
  const { t } = useTranslation();
  const { initialValues, exam_id } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      ...initialValues,
      //   playlist_url: [
      //     {
      //       uid: "-1",
      //       name: initialValues?.playlist_url ? "Document" : "",
      //       status: "done",
      //       url: initialValues?.playlist_url,
      //     },
      //   ],
    });
  }, [initialValues]);

  const handleSubmit = (values) => {
    values.exam_id = exam_id;
    // values.playlist_url = values.playlist_url?.[0]?.url;
    if (initialValues?.id) {
      let variables = {
        id: initialValues?.id,
        data: values,
      };
      let keys = [{ key: "update_exam_play_list", loading: true }];
      dispatch(
        dynamicRequest(keys, update_exam_play_list_mutation, variables, "M")
      );
    } else {
      let variables = {
        data: values,
      };
      let keys = [{ key: "create_exam_play_list", loading: true }];
      dispatch(
        dynamicRequest(keys, create_exam_play_list_mutation, variables, "M")
      );
    }
  };

  return (
    <Form
      form={form}
      name={"Playlist Detailss"}
      layout={"vertical"}
      onFinish={handleSubmit}
      className="user_form ant-select-selector ant-select-selection-search"
    >
      <VForm.TextBox
        label={t("Name")}
        field={"name"}
        rules={[
          {
            required: true,
            message: "Name is Required",
          },
        ]}
      />
      <VForm.TextBox
        label={t("Playlist URL")}
        field={"playlist_url"}
        rules={[
          {
            required: true,
            message: "Playlist URL is Required",
          },
        ]}
      />
      <VForm.Number
        label={t("Sort Order")}
        field={"sort_order"}
        rules={[
          {
            required: true,
            message: "Sort Order is Required",
          },
        ]}
      />
      <HStack space="10" justifyContent={"flex-end"} marginRight="40px">
        <VForm.Button loading={false} disabled={false}>
          {t("Submit")}
        </VForm.Button>
        <Button onClick={props.close}>{t("close")}</Button>
      </HStack>
    </Form>
  );
};
export default ExamVideoPlayListDetails;
