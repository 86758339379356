import {
  dynamicRequest,
  get_report_query,
  useDynamicSelector,
  get_all_exam_query,
  get_candidate_report,
} from "@services/redux";
import { Button } from "antd";
import { Box, HStack, Text } from "native-base";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import CandidateReport from "./user_report";
const Report = () => {
  const dispatch = useDispatch();
  const XLSX = require("xlsx");

  const report = useDynamicSelector("getReport");
  const [is_download, setIsDownload] = useState(false);

  const testWiseData = report.test_wise_submission_count_for_each_exam_data;
  const districtWiseData = report.district_wise_data;
  const subscribers_month_wise_data = report.subscribers_month_wise_data;
  const exam_wise_test_taken_data = report.exam_wise_test_taken_data;
  const notes_download_data = report.notes_download_data;
  const video_view_data = report.video_view_data;
  const notification_data = report.notification_data;
  const syllabus_view_data = report.syllabus_view_data;
  const syllabus_download_data = report.syllabus_download_data;
  const class_schedule_view_data = report.class_schedule_view_data;
  const class_schedule_download_data = report.class_schedule_download_data;
  const test_schedule_view_data = report.test_schedule_view_data;
  const test_schedule_download_data = report.test_schedule_download_data;
  const previous_year_question_paper_download_data =
    report.previous_year_question_paper_download_data;
  const previous_year_question_paper_view_data =
    report.previous_year_question_paper_view_data;
  const { items: exams } = useDynamicSelector("get_all_exams");
  const handleGetReport = () => {
    let keys = [{ key: "getReport", loading: true }];
    let query = get_report_query;
    let variables = {};
    dispatch(dynamicRequest(keys, query, variables));
    setIsDownload(true);
  };

  useEffect(() => {
    let keys = [{ key: "get_all_exams", loading: true }];
    dispatch(dynamicRequest(keys, get_all_exam_query, { is_published: true }));
  }, []);
  useEffect(() => {
    if (!report.loading && is_download) {
      downloadCombinedExcelReport();
    }
  }, [report, is_download]);

  function getMonthName(month) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return monthNames[parseInt(month, 10) - 1] || "";
  }

  const functionForLoop = (
    exams,
    data,
    rowFirstHalf,
    rowSecondHalf,
    month,
    year
  ) => {
    return exams.forEach((exam) => {
      const examData = data?.find(
        (data) => data.id === exam.id || data.exam_id === exam.id
      );
      if (examData) {
        const stats = examData?.monthly_stats.find(
          (stats) =>
            stats.year_month === `${year}-${String(month).padStart(2, "0")}`
        );
        if (stats) {
          rowFirstHalf.push(stats.first_half_count || 0);
          rowSecondHalf.push(stats.second_half_count || 0);
        } else {
          rowFirstHalf.push(0);
          rowSecondHalf.push(0);
        }
      } else {
        rowFirstHalf.push(0);
        rowSecondHalf.push(0);
      }
    });
  };

  function downloadCombinedExcelReport() {
    const wb = XLSX.utils.book_new();

    const additionalHeaders = [
      "",
      "",
      "Total No of Test Count",
      "Notes Downloads",
      "Video Click Count",
      "NOTIFICATION",
      "SYLLABUS (VIEW)",
      "SYLLABUS (DOWNLOAD)",
      "CLASS SCHEDULE VIEW",
      "CLASS SCHEDULE DOWNLOAD",
      "TEST SCHEDULE VIEW",
      "TEST SCHEDULE DOWNLOAD",
      "Previous Year Question Paper VIEW",
      "Previous Year Question Paper Download",
    ];

    const formattedData = [
      additionalHeaders,
      [
        "Month",
        "No of Subscribers",
        ...exams.map((exam) => `${exam.name} `),
        ...exams.map((exam) => `${exam.name} `),
        ...exams.map((exam) => `${exam.name} `),
        ...exams.map((exam) => `${exam.name} `),
        ...exams.map((exam) => `${exam.name} `),
        ...exams.map((exam) => `${exam.name} `),
        ...exams.map((exam) => `${exam.name} `),
        ...exams.map((exam) => `${exam.name} `),
        ...exams.map((exam) => `${exam.name} `),
        ...exams.map((exam) => `${exam.name} `),
        ...exams.map((exam) => `${exam.name} `),
        ...exams.map((exam) => `${exam.name} `),
      ],
    ];

    const startDate = new Date("2023-01-01");
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    for (let year = startDate.getFullYear(); year <= currentYear; year++) {
      for (let month = 1; month <= 12; month++) {
        if (year === currentYear && month > currentMonth) {
          break; // Stop if we reach the current month of the current year
        }

        const monthText = getMonthName(month);

        const subscribersEntry = subscribers_month_wise_data?.find(
          (entry) =>
            entry.year_month === `${year}-${String(month).padStart(2, "0")}`
        );

        const rowFirstHalf = [
          `${monthText}-${year} (FH)`,
          subscribersEntry ? subscribersEntry.first_half_count : 0,
        ];

        const rowSecondHalf = [
          `${monthText}-${year} (SH)`,
          subscribersEntry ? subscribersEntry.second_half_count : 0,
        ];

        functionForLoop(
          exams,
          exam_wise_test_taken_data,
          rowFirstHalf,
          rowSecondHalf,
          month,
          year
        );
        functionForLoop(
          exams,
          notes_download_data,
          rowFirstHalf,
          rowSecondHalf,
          month,
          year
        );
        functionForLoop(
          exams,
          video_view_data,
          rowFirstHalf,
          rowSecondHalf,
          month,
          year
        );
        functionForLoop(
          exams,
          notification_data,
          rowFirstHalf,
          rowSecondHalf,
          month,
          year
        );
        functionForLoop(
          exams,
          syllabus_view_data,
          rowFirstHalf,
          rowSecondHalf,
          month,
          year
        );
        functionForLoop(
          exams,
          syllabus_download_data,
          rowFirstHalf,
          rowSecondHalf,
          month,
          year
        );
        functionForLoop(
          exams,
          class_schedule_view_data,
          rowFirstHalf,
          rowSecondHalf,
          month,
          year
        );
        functionForLoop(
          exams,
          class_schedule_download_data,
          rowFirstHalf,
          rowSecondHalf,
          month,
          year
        );
        functionForLoop(
          exams,
          test_schedule_view_data,
          rowFirstHalf,
          rowSecondHalf,
          month,
          year
        );
        functionForLoop(
          exams,
          test_schedule_download_data,
          rowFirstHalf,
          rowSecondHalf,
          month,
          year
        );
        functionForLoop(
          exams,
          previous_year_question_paper_view_data,
          rowFirstHalf,
          rowSecondHalf,
          month,
          year
        );
        functionForLoop(
          exams,
          previous_year_question_paper_download_data,
          rowFirstHalf,
          rowSecondHalf,
          month,
          year
        );

        // Add rows to the formatted data
        formattedData.push(rowFirstHalf);
        formattedData.push(rowSecondHalf);
      }
    }

    const combinedDataSheet = XLSX.utils.aoa_to_sheet(formattedData);

    let total_len = 2;
    let len = exams?.length - 1;
    for (let index = 2; index < additionalHeaders.length; index++) {
      const header = additionalHeaders[index];

      const mergeRange = {
        s: { r: 0, c: total_len },
        e: { r: 0, c: total_len + len }, // Adjusted the calculation
      };

      combinedDataSheet["!merges"] = combinedDataSheet["!merges"]
        ? combinedDataSheet["!merges"].concat([mergeRange])
        : [mergeRange];

      combinedDataSheet[XLSX.utils.encode_cell({ r: 0, c: total_len })] = {
        t: "s",
        v: header,
        w: header,
        s: { horizontal: "center", vertical: "center" }, // Align text in the center
      };

      total_len += len + 1;
    }
    XLSX.utils.book_append_sheet(wb, combinedDataSheet, "Overall");

    //District report
    const districtSheet = districtWiseData?.map((item) => ({
      District: item.district_name,
      "No of Subscribers": item.user_count,
    }));
    const ws = XLSX.utils.json_to_sheet(districtSheet || []);
    XLSX.utils.book_append_sheet(wb, ws, "DistrictWiseReport");
    const groupedTestData = {};
    testWiseData?.forEach((item) => {
      const examId = item.exam_id;
      if (!groupedTestData[examId]) {
        groupedTestData[examId] = [];
      }
      groupedTestData[examId].push(item);
    });
    Object.entries(groupedTestData).forEach(([examId, examData]) => {
      const examName = examData[0].exam_name;
      const sheetData = createSheetData(examData);

      const titleRow = ["No of Persons Attended"];
      const headers = ["Test Name"];

      sheetData[0].forEach((month) => {
        console.log("month", month);
        if (month !== "Test Name") {
          const [year, monthNumber] = month.split("-").map(Number);
          const monthText = getMonthName(monthNumber);
          console.log("year", year);
          // const yearText = year === currentYear ? "" : ` ${year}`;
          titleRow.push("");
          headers.push(
            `${monthText}-${year} (FH)`,
            `${monthText}-${year} (SH)`
          );
        }
      });

      const testWs = XLSX.utils.aoa_to_sheet(
        [titleRow, headers].concat(sheetData.slice(1))
      );
      XLSX.utils.book_append_sheet(wb, testWs, examName);
    });

    XLSX.writeFile(wb, "Nokkam Monthwise report.xlsx");
    setIsDownload(false);
  }

  function createSheetData(examData) {
    const sheetData = [["Test Name"]];
    const testNames = new Set();

    examData.forEach((item) => {
      const testName = item.question_paper_name;
      const monthlyStats = item.monthly_submission_stats || [];

      testNames.add(testName);

      monthlyStats.forEach((stats) => {
        const yearMonth = stats.year_month;
        if (!sheetData[0].includes(yearMonth)) {
          sheetData[0].push(yearMonth);
        }
      });
    });

    sheetData[0].sort();

    testNames.forEach((testName) => {
      const row = [testName];
      sheetData[0].forEach((month) => {
        if (month !== "Test Name") {
          const monthlyStat = examData
            .find((item) => item.question_paper_name === testName)
            ?.monthly_submission_stats.find(
              (stat) => stat.year_month === month
            );

          if (monthlyStat) {
            row.push(
              monthlyStat.first_half_count || 0,
              monthlyStat.second_half_count || 0
            );
          } else {
            row.push(0, 0);
          }
        }
      });
      sheetData.push(row);
    });

    return sheetData;
  }

  return (
    <div>
      <Box top="10px" zIndex="2" bg="white">
        <Text fontSize="md" fontWeight="bold">
          {"Report"}
        </Text>
      </Box>
      <HStack space={"3"} justifyContent="center" m={3}>
        <Button
          onClick={handleGetReport}
          type="primary"
          loading={report.loading}
        >
          Download Report
        </Button>
        <CandidateReport />
      </HStack>
    </div>
  );
};

export default Report;
