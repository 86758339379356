import React, { useState } from "react";
import {
  Box,
  Heading,
  FormControl,
  Input,
  Image,
  Button,
  VStack,
} from "native-base";
import memberImage from "@assets/images/c_p_image.jpg";
import { useDispatch } from "react-redux";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
  updateChangePassword,
} from "@services/redux";
import { useWindowDimensions } from "react-native";
import { showNotification } from "@helpers/notify";
import { navigate } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";

const ChangePasswordPage = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const dispatch = useDispatch();

  const ChangePassword = () => {
    let user_login_key = [{ key: "user_login", loading: true }];
    let user_login_query = updateChangePassword;
    let user_login_variables = {
      current_password: currentPassword,
      new_password: newPassword,
    };
    dispatch(
      dynamicRequest(user_login_key, user_login_query, user_login_variables)
    );
  };

  const handleChangePassword = () => {
    if (
      newPassword === confirmPassword &&
      currentPassword === currentPassword
    ) {
      ChangePassword();
      localStorage.clear();
      navigate(ROUTES.LOGIN);
    } else if (currentPassword !== currentPassword) {
      showNotification({
        type: "error",
        message: "currentPassword Password Not Match",
      });
    } else {
      showNotification({
        type: "error",
        message: "New Password & Confirm Password Not Match",
      });
    }
  };

  const windowDimensions = useWindowDimensions();
  return (
    <Box flexDirection="row">
      <Box height={windowDimensions.height} width={["100%", "100%", "50%"]}>
        <Image
          source={memberImage}
          alt="Member"
          resizeMode="cover"
          width="100%"
          height="100%"
        />
      </Box>

      <Box
        width={["100%", "100%", "50%"]}
        justifyContent="center"
        p={[4, 4, 100]}
        backgroundColor="blueGray.100"
      >
        <Heading mb={6}>Change Password</Heading>

        <VStack space={4}>
          <FormControl>
            <FormControl.Label>Current Password</FormControl.Label>
            <Input
              secureTextEntry
              value={currentPassword}
              onChangeText={(value) => setCurrentPassword(value)}
            />
          </FormControl>

          <FormControl>
            <FormControl.Label>New Password</FormControl.Label>
            <Input
              secureTextEntry
              value={newPassword}
              onChangeText={(value) => setNewPassword(value)}
            />
          </FormControl>

          <FormControl>
            <FormControl.Label>Confirm New Password</FormControl.Label>
            <Input
              secureTextEntry
              value={confirmPassword}
              onChangeText={(value) => setConfirmPassword(value)}
            />
          </FormControl>

          <Button onPress={handleChangePassword}>Change Password</Button>
        </VStack>
      </Box>
    </Box>
  );
};

export default ChangePasswordPage;
