import { gql } from "@apollo/client";

export const get_all_submission_query = gql`
  query getAllUserTestSubmissionsForEvaluator(
    $page_number: Float
    $page_limit: Float
    $search_string: String
    $status: String
    $question_paper_id: String
  ) {
    get_all_submissions: getAllUserTestSubmissionsForEvaluator(
      page_number: $page_number
      page_limit: $page_limit
      search_string: $search_string
      status: $status
      question_paper_id: $question_paper_id
    ) {
      items {
        id
        status
        uploaded_at
        evaluated_at
        evaluator_assigned_at
        user {
          id
          name
        }
        question_paper {
          id
          title
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const get_one_submission_query = gql`
  query getUserTestSubmission($id: String) {
    get_submission: getUserTestSubmission(id: $id) {
      id
      status
      user {
        id
        name
      }
      question_paper {
        id
        title
        total_no_of_pages
        question_paper_sections {
          id
          name
          max_mark
          section_questions {
            id
            number
            content
            page_numbers
            question_paper_section_id
          }
        }
      }
    }
  }
`;

export const create_section_comment_mutation = gql`
  mutation createUserSectionEvaluation($data: user_section_evaluation_input) {
    create_section_comment: createUserSectionEvaluation(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const create_general_comment_mutation = gql`
  mutation createUserPaperEvaluation($data: user_paper_evaluation_input) {
    create_general_comment: createUserPaperEvaluation(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const create_question_evaluation_mutation = gql`
  mutation questionEvaluationMultipleUpdate(
    $data: [user_question_evaluation_multiple_update]
  ) {
    create_question_evaluation: questionEvaluationMultipleUpdate(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_submission_results_query = gql`
  query getUserTestSubmissionResults($id: String) {
    get_submission_results: getUserTestSubmission(id: $id) {
      user_paper_evaluations {
        id
        comment
      }
      user_section_evaluations {
        id
        question_paper_section_id
        comment
      }
      user_question_evaluations {
        id
        user_answer_id
        question_id
        mark
        comment
      }
    }
  }
`;

export const evaluation_ended_mutation = gql`
  mutation evaluationEnded($id: String!) {
    evaluation_ended: evaluationEnded(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const evaluation_started_mutation = gql`
  mutation evaluationStarted($id: String!) {
    evaluation_started: evaluationStarted(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
