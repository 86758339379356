import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  VStack,
  Text,
  HStack,
  Pressable,
  Center,
  IconButton,
} from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import {
  useDynamicSelector,
  dynamicRequest,
  get_one_Exam_query,
  get_all_previous_year_question_paper_query,
} from "@services/redux";
import AntdModal from "@views/components/ui/antd_modal";
import { Button, Form, Modal } from "antd";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory, useParams } from "react-router-dom";
import SimpleGrid from "@views/components/ui/simple_grid/simple_grid";
import VForm from "@views/components/ui/antd_form";
import PreviousYearQuestionPaperDetails from "./previous_year_paper_details";
import { IoIosPaper } from "react-icons/io";

const PreviousYearPaper = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [actionType, setActionType] = useState(null);
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { exam_id, exam_name } = useParams();
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);

  const { name } = useDynamicSelector("get_exam");

  const {
    items: get_all_previous_year_papers,
    loading: previous_year_paper_loading,
  } = useDynamicSelector("get_all_previous_year_question_papers");
  const handleAdd = (x) => {
    setActionType(x);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };
  useEffect(() => {
    let keys = [
      { key: "get_all_previous_year_question_papers", loading: true },
    ];
    dispatch(
      dynamicRequest(keys, get_all_previous_year_question_paper_query, {
        exam_id,
      })
    );
  }, []);

  useEffect(() => {
    let keys = [{ key: "get_exam", loading: true }];
    dispatch(
      dynamicRequest(keys, get_one_Exam_query, {
        id: exam_id,
      })
    );
  }, [exam_id]);
  useEffect(() => {
    let initialValues = {};

    if (actionType && get_all_previous_year_papers) {
      initialValues = get_all_previous_year_papers?.find(
        (x) => x.year == actionType
      );
    }

    setActionItem(initialValues);
  }, [get_all_previous_year_papers, actionType]);
  const year = [
    new Date().getFullYear(),
    new Date().getFullYear() - 1,
    new Date().getFullYear() - 2,
    new Date().getFullYear() - 3,
    new Date().getFullYear() - 4,
    new Date().getFullYear() - 5,
    new Date().getFullYear() - 6,
    new Date().getFullYear() - 7,
    new Date().getFullYear() - 8,
    new Date().getFullYear() - 9,
    new Date().getFullYear() - 10,
    new Date().getFullYear() - 11,
    new Date().getFullYear() - 12,
    new Date().getFullYear() - 13,
    new Date().getFullYear() - 14,
    new Date().getFullYear() - 15,
    new Date().getFullYear() - 16,
    new Date().getFullYear() - 17,
    new Date().getFullYear() - 18,
    new Date().getFullYear() - 19,
    new Date().getFullYear() - 20,
    new Date().getFullYear() - 21,
    new Date().getFullYear() - 22,
    new Date().getFullYear() - 23,
    new Date().getFullYear() - 24,
    new Date().getFullYear() - 25,
    new Date().getFullYear() - 26,
    new Date().getFullYear() - 27,
    new Date().getFullYear() - 28,
    new Date().getFullYear() - 29,
    new Date().getFullYear() - 30,
  ];
  return (
    <>
      <Box top="10px" zIndex="2">
        <Text fontSize="md" fontWeight="bold">
          {`${exam_name} -  Previous Year Question Paper`}
        </Text>
      </Box>

      <Box width="100%" mt="10px" marginBottom="20px">
        <VStack space={5}>
          <SimpleGrid
            maxw="100%"
            w="100%"
            columns={{
              base: 1,
              xs: 1,
              md: 3,
              sm: 2,
              lg: 4,
              xl: 4,
              "2xl": 6,
            }}
            spacingY={20}
            spacingX={21}
          >
            {year?.map((x) => {
              return (
                <Box
                  mx="5"
                  width="240px"
                  h="100px"
                  alignSelf={"center"}
                  //   bg="#8080801f"
                  padding="2"
                  shadow="3"
                  borderRadius="5px"
                >
                  <Box
                    padding={5}
                    alignItems="center"
                    style={{
                      justifyContent: "center",
                      flex: 1,
                    }}
                    onClick={() => {
                      handleAdd(x);
                    }}
                  >
                    <HStack space={10}>
                      <Box>
                        <Text
                          textALign={"left"}
                          fontWeight={"600"}
                          fontSize={"18px"}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          {x}
                        </Text>
                      </Box>
                      <IoIosPaper size="25" />
                    </HStack>
                  </Box>
                </Box>
              );
            })}
          </SimpleGrid>
        </VStack>
        <HStack justifyContent={"flex-end"}>
          <Button
            type="default"
            danger
            onClick={() => {
              history.goBack();
            }}
          >
            Back
          </Button>
        </HStack>
      </Box>
      <AntdModal
        isOpen={modalOpen}
        onClose={handleModalClose}
        width={650}
        header={actionType + "   Question Paper"}
        component={
          <PreviousYearQuestionPaperDetails
            label={t("submit")}
            close={handleModalClose}
            initialValues={actionItem}
            exam_id={exam_id}
            actionType={actionType}
          />
        }
      />
    </>
  );
};
export default PreviousYearPaper;
