import React, { useEffect, useState } from "react";
import VForm from "@views/components/ui/antd_form";
import { Form, Button } from "antd";
import { useDispatch } from "react-redux";
import {
  dynamicRequest,
  create_note_mutation,
  update_note_mutation,
  useDynamicSelector,
  get_all_topics_query,
} from "@services/redux";
import { useTranslation } from "react-i18next";
import { HStack, useBreakpointValue } from "native-base";

const NoteDetails = (props) => {
  const { t } = useTranslation();
  const { initialValues, topic_id, exam_notes } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { items: topics } = useDynamicSelector("get_all_topics");

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
  }, [initialValues]);
  useEffect(() => {
    let keys = [{ key: "get_all_topics", loading: true }];
    dispatch(dynamicRequest(keys, get_all_topics_query));
  }, []);
  const handleSubmit = (values) => {
    values.topic_id = exam_notes ? values.topic_id : topic_id;

    if (initialValues?.id) {
      let variables = {
        id: initialValues?.id,
        data: { ...values, url: values?.url?.[0]?.url },
      };
      let keys = [{ key: "update_note", loading: true }];
      dispatch(dynamicRequest(keys, update_note_mutation, variables, "M"));
    } else {
      let variables = {
        data: { ...values, url: values?.url?.[0]?.url },
      };
      let keys = [{ key: "create_note", loading: true }];
      dispatch(dynamicRequest(keys, create_note_mutation, variables, "M"));
    }
  };
  const columns = useBreakpointValue({
    base: 1,
    md: 2,
  });

  return (
    <Form
      form={form}
      name={"Note Details"}
      layout={"vertical"}
      onFinish={handleSubmit}
      className="user_form ant-select-selector ant-select-selection-search"
    >
      <VForm.TextBox
        label={t("Name")}
        field={"name"}
        // rules={[
        //   {
        //     required: true,
        //     message: "Name is Required",
        //   },
        // ]}
      />
      {exam_notes && (
        <VForm.Select
          label={t("Topics")}
          field={"topic_id"}
          isMulti={false}
          options={topics}
        />
      )}
      <VForm.File
        label={t("File")}
        field={"url"}
        rules={[
          {
            required: true,
            message: "File is Required",
          },
        ]}
      />
      {/* <VForm.File
        label={t("Icon")}
        field={"icon"}
        rules={[
          {
            required: false,
            message: "Icon is Required",
          },
        ]}
      /> */}
      <VForm.Number
        label={t("Sort Order")}
        field={"sort_order"}
        rules={[
          {
            required: true,
            message: "Sort Order is Required",
          },
        ]}
      />
      {/* <VForm.TextArea label={t("Description")} field={"Description"} /> */}

      <HStack space="10" justifyContent={"flex-end"} marginRight="40px">
        <VForm.Button loading={false} disabled={false}>
          {t("Submit")}
        </VForm.Button>
        <Button onClick={props.close}>{t("close")}</Button>
      </HStack>
    </Form>
  );
};
export default NoteDetails;
