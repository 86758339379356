import React, { useEffect } from "react";
import {
  Box,
  HStack,
  Menu,
  VStack,
  Button,
  Pressable,
  Text,
} from "native-base";
import Avatar from "react-avatar";
import { retrieveItem, useStorageItem } from "@helpers/storage";
import { ROUTES } from "@views/routes/my_routes";
import { navigate } from "@helpers/navigator";
import { useDispatch } from "react-redux";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
  userLogin,
} from "@services/redux";
import { ProjectName } from "@helpers/constants";

let defaultStyles = {
  height: "70px",
};

const HeaderBar = (props) => {
  let height = props.height || props.h || defaultStyles.height;
  const dispatch = useDispatch();
  const name = retrieveItem("name");

  const handleLogout = () => {
    dispatch(dynamicClear("admin_login"));
    localStorage.clear();
    navigate(ROUTES.LOGIN);
  };
  const handleChangePassword = () => {
    dispatch(dynamicClear("current_password"));
    // localStorage.clear();
    navigate(ROUTES.FORGOT_PASSWORD);
  };

  const capitalizeFirstLetter = (string) => {
    return string?.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <Box h={height} w="100%" bg="#ffffff">
      <HStack
        h="90%"
        w="100%"
        bgImage="linear-gradient(406deg, rgb(23 153 199) 20%, #409161 80%)"
        style={{
          boxShadow: "0px 0px 20px rgb(229 218 255)",
        }}
        space="2"
        justifyContent={"space-between"}
      >
        <Box flex={1} justifyContent="center" pl="4">
          <Text bold fontSize="xl" fontWeight="bold" color="#ffffff">
            {ProjectName}
          </Text>
        </Box>
        {"   "}

        <Box my={"auto"}>
          <HStack>
            <Box right="10px" ml="6">
              <Menu
                w="160"
                position="absolute"
                right="0px"
                top="20px"
                style={{
                  border: "1px solid #d7d7d7",
                  boxShadow: "-2px 2px #7a7a7a42",
                }}
                shouldOverlapWithTrigger={true}
                placement={"left bottom"}
                trigger={(triggerProps) => {
                  return (
                    <Pressable
                      alignSelf="center"
                      alignItems="center"
                      variant="solid"
                      {...triggerProps}
                    >
                      <HStack>
                        <Text bold fontSize="md" fontWeight="" color="#ffffff">
                          {`Welcome ${capitalizeFirstLetter(name)} !`}
                        </Text>
                        <Box right="10px" ml="6">
                          <Avatar
                            round
                            size="25"
                            src={
                              "https://aim-assets.s3.ap-south-1.amazonaws.com/default-avatar.png"
                            }
                          />
                        </Box>
                      </HStack>
                    </Pressable>
                  );
                }}
              >
                <Menu.Item onPress={handleChangePassword}>
                  Change Password
                </Menu.Item>
                <Menu.Item onPress={handleLogout}>Logout</Menu.Item>
              </Menu>
            </Box>
          </HStack>
        </Box>
      </HStack>
    </Box>
  );
};

export default HeaderBar;
