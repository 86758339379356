import React, { useEffect, useState } from "react";
import { Box, Button, HStack, Text, VStack, Pressable } from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import AntdModal from "@views/components/ui/antd_modal";
import { showNotification } from "@helpers/notify";
import {
  useDynamicSelector,
  dynamicRequest,
  dynamicClear,
  get_one_text_book_class_query,
} from "@services/redux";
import { AiOutlineEdit, AiOutlineDelete, AiFillYoutube } from "react-icons/ai";
import Wrapper from "@views/components/ui/wrapper";
import { Pagination, Popconfirm, Table, Typography } from "antd";
import { useHistory, useLocation, useParams } from "react-router-dom";
import SubjectDetails from "./subject_details";
import {
  delete_text_book_subject_mutation,
  get_all_text_book_subject_query,
} from "@services/redux/slices/dynamic_entity/graphql/graphql_text_book_subject";
import { ROUTES } from "@views/routes/my_routes";

const SubjectList = () => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [actionItem, setActionItem] = useState({});
  const [current, setCurrent] = useState(1);
  const { board_id, class_id } = useParams();
  const [pageLimit, setPageLimit] = useState(10);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const history = useHistory();

  const {
    items: subject_items,
    pagination,
    loading: subject_loading,
  } = useDynamicSelector("get_all_text_book_subject");
  const {
    status: createStatus,
    error: createError,
    loading: createLoading,
  } = useDynamicSelector("create_text_book_subject");
  const {
    status: updateStatus,
    error: updateError,
    loading: updateLoading,
  } = useDynamicSelector("update_text_book_subject");
  const { status: deleteStatus, error: deleteError } = useDynamicSelector(
    "delete_text_book_subject"
  );
  const handleModalClose = () => {
    setModalOpen(false);
  };
  const handleAdd = () => {
    setActionItem({});
    setActionType("add");
    setModalOpen(true);
  };

  const handleEdit = (item) => {
    setActionItem(item);
    setActionType("edit");
    setModalOpen(true);
  };

  const handleDelete = (record) => {
    let keys = [{ key: "delete_text_book_subject", loading: true }];
    dispatch(
      dynamicRequest(keys, delete_text_book_subject_mutation, {
        id: record?.id,
      })
    );
  };

  useEffect(() => {
    let keys = [{ key: "get_all_text_book_subject", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_text_book_subject_query, {
        page_number: current,
        page_limit: pageLimit,
        text_book_class_id: class_id,
      })
    );
    keys = [{ key: "get_one_text_book_class", loading: true }];
    dispatch(
      dynamicRequest(keys, get_one_text_book_class_query, {
        id: class_id,
      })
    );
  }, []);

  useEffect(() => {
    if (actionType === "add" && createStatus === "success") {
      handleModalClose();
      showNotification({
        type: "success",
        message: `${t("text_book_subject_create_success_msg")}`,
      });
      dispatch(dynamicClear("create_text_book_subject"));

      let keys = [{ key: "get_all_text_book_subject", loading: true }];
      dispatch(
        dynamicRequest(keys, get_all_text_book_subject_query, {
          page_number: current,
          page_limit: pageLimit,
          text_book_class_id: class_id,
        })
      );
      keys = [{ key: "get_one_text_book_class", loading: true }];
      dispatch(
        dynamicRequest(keys, get_one_text_book_class_query, {
          id: class_id,
        })
      );
    } else if (actionType === "edit" && updateStatus === "success") {
      handleModalClose();

      showNotification({
        type: "success",
        message: `${t("text_book_subject_update_success_msg")}`,
      });
      dispatch(dynamicClear("update_text_book_subject"));

      let keys = [{ key: "get_all_text_book_subject", loading: true }];
      dispatch(
        dynamicRequest(keys, get_all_text_book_subject_query, {
          page_number: current,
          page_limit: pageLimit,
          text_book_class_id: class_id,
        })
      );
      keys = [{ key: "get_one_text_book_class", loading: true }];
      dispatch(
        dynamicRequest(keys, get_one_text_book_class_query, {
          id: class_id,
        })
      );
    } else if (deleteStatus === "success") {
      showNotification({
        type: "success",
        message: `${t("text_book_subject_delete_success_msg")}`,
      });
      dispatch(dynamicClear("delete_text_book_subject"));

      let keys = [{ key: "get_all_text_book_subject", loading: true }];
      dispatch(
        dynamicRequest(keys, get_all_text_book_subject_query, {
          page_number: current,
          page_limit: pageLimit,
          text_book_class_id: class_id,
        })
      );
      keys = [{ key: "get_one_text_book_class", loading: true }];
      dispatch(
        dynamicRequest(keys, get_one_text_book_class_query, {
          id: class_id,
        })
      );
    } else if (actionType === "add" && createStatus === "failure") {
      handleModalClose();
      showNotification({
        type: "failure",
        message: `${t("text_book_subject_create_failure_msg")}`,
      });
      dispatch(dynamicClear("create_text_book_subject"));

      let keys = [{ key: "get_all_text_book_subject", loading: true }];
      dispatch(
        dynamicRequest(keys, get_all_text_book_subject_query, {
          page_number: current,
          page_limit: pageLimit,
          text_book_class_id: class_id,
        })
      );
      keys = [{ key: "get_one_text_book_class", loading: true }];
      dispatch(
        dynamicRequest(keys, get_one_text_book_class_query, {
          id: class_id,
        })
      );
    } else if (actionType === "edit" && updateStatus === "failure") {
      handleModalClose();

      showNotification({
        type: "failure",
        message: `${t("text_book_subject_update_failure_msg")}`,
      });
      dispatch(dynamicClear("update_text_book_subject"));

      let keys = [{ key: "get_all_text_book_subject", loading: true }];
      dispatch(
        dynamicRequest(keys, get_all_text_book_subject_query, {
          page_number: current,
          page_limit: pageLimit,
          text_book_class_id: class_id,
        })
      );
      keys = [{ key: "get_one_text_book_class", loading: true }];
      dispatch(
        dynamicRequest(keys, get_one_text_book_class_query, {
          id: class_id,
        })
      );
    } else if (deleteStatus === "failure") {
      showNotification({
        type: "failure",
        message: `${t("text_book_subject_delete_failure_msg")}`,
      });
      dispatch(dynamicClear("delete_text_book_subject"));

      let keys = [{ key: "get_all_text_book_subject", loading: true }];
      dispatch(
        dynamicRequest(keys, get_all_text_book_subject_query, {
          page_number: current,
          page_limit: pageLimit,
          text_book_class_id: class_id,
        })
      );
      keys = [{ key: "get_one_text_book_class", loading: true }];
      dispatch(
        dynamicRequest(keys, get_one_text_book_class_query, {
          id: class_id,
        })
      );
    }
  }, [createStatus, updateStatus, deleteStatus]);

  const fetchData = (page_number, page_limit) => {
    let keys = [{ key: "get_all_text_book_subject", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_text_book_subject_query, {
        page_number: page_number,
        page_limit: page_limit,
        text_book_class_id: class_id,
      })
    );
    setCurrent(page_number);
    setPageLimit(page_limit);
  };
  const handleBack = () => {
    history.goBack();
  };
  let columns = [
    {
      title: t("table:Name"),
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
      // render: (data) => (
      //   <Pressable onPress={() => handleResponse(data)}>
      //     <Text color={"#0000ff"}>{data.name}</Text>
      //   </Pressable>
      // ),
    },
    {
      title: t("table:no_of_views"),
      dataIndex: "subject_note_view_count",
      key: "subject_note_view_count",
      sortable: false,
      align: "left",
      width: "150px",
    },
    {
      title: t("table:no_of_downloads"),
      dataIndex: "subject_note_download_count",
      key: "subject_note_download_count",
      sortable: false,
      align: "left",
      width: "200px",
    },
    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "center",
      width: "150px",
      render: (record) => {
        return (
          <span>
            <Typography.Link
              onClick={() => handleEdit(record)}
              style={{ marginRight: 15 }}
            >
              <AiOutlineEdit />
              {/* <Text color="blue.400">Edit</Text> */}
            </Typography.Link>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record)}
            >
              <AiOutlineDelete />
              {/* <Text color="red.400">
                  <a>Delete</a>
                </Text> */}
            </Popconfirm>
          </span>
        );
      },
    },
  ];
  return (
    <>
      <Box variant="text_book_board_heading_box">
        <Text variant="text_book_board_heading_text">
          {state?.name}th &nbsp; {t("subject")}
        </Text>
      </Box>
      <Box variant="text_book_board_top_box">
        <VStack variant="text_book_board_top_vstack">
          <HStack variant="add_text_book_board_button_hstack">
            <Box key={`header-action`}>
              <Button type="primary" onPress={handleAdd}>
                {t("add_subject")}
              </Button>
            </Box>
          </HStack>
          <Wrapper>
            <Table
              loading={subject_loading}
              columns={columns}
              dataSource={subject_items}
              pagination={false}
            />
          </Wrapper>
          <Pagination
            total={pagination?.total_count}
            showSizeChanger={true}
            current={current}
            hideOnSinglePage={false}
            onChange={fetchData}
            pageSizeOptions={[1, 5, 10, 20, 50]}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} subjects`
            }
          />
          <HStack justifyContent={"flex-end"}>
            <Button variant={"outline"} onPress={handleBack}>
              Back
            </Button>
          </HStack>
        </VStack>
      </Box>
      <AntdModal
        isOpen={modalOpen}
        onClose={handleModalClose}
        width={450}
        header={actionType === "add" ? t("add_subject") : t("update_subject")}
        component={
          <SubjectDetails
            label={actionType === "add" ? t("add") : t("update")}
            close={handleModalClose}
            initialValues={actionItem}
            board_id={board_id}
            class_id={class_id}
          />
        }
      />
    </>
  );
};

export default SubjectList;
