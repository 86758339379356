import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  VStack,
  Text,
  HStack,
  Image,
  Menu,
  Pressable,
  Hidden,
  Card,
  Center,
} from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import Loader from "react-js-loader";
import Avatar from "react-avatar";
import {
  get_all_submission_query,
  useDynamicSelector,
  dynamicRequest,
  dynamicClear,
  get_all_question_paper_query,
} from "@services/redux";
import { Col, Form, Pagination, Row, Select, Table } from "antd";
import { navigate } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";
import EvaluatorHeaderBar from "@views/components/ui/header_bar/evaluator_header";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";

const SubmissionList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { status } = useParams();
  const { t } = useTranslation();
  const [current, setCurrent] = useState(1);
  const [selected_status, setSelectedStatus] = useState(status);
  const [selected_question_paper, setSelectedQuestionPaper] = useState();
  const { status: evaluation_ended_status, loading: evaluation_ended_loading } =
    useDynamicSelector("evaluation_ended");

  const {
    items: submissions,
    loading,
    pagination,
  } = useDynamicSelector("get_all_submissions");
  const { items: question_papers } = useDynamicSelector("get_all_question");
  useEffect(() => {
    let keys = [{ key: "get_all_submissions", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_submission_query, {
        page_number: current,
        page_limit: 10,
        status: status,
      })
    );
    let question_keys = [{ key: "get_all_question", loading: true }];
    dispatch(dynamicRequest(question_keys, get_all_question_paper_query, {}));
  }, []);

  const fetchData = (value) => {
    let keys = [{ key: "get_all_submissions", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_submission_query, {
        page_number: value,
        page_limit: 10,
        status: selected_status,
      })
    );
    setCurrent(value);
  };
  const handleStatus = (status) => {
    switch (status) {
      case "evaluation_started":
        return "Partially Evaluated";
      case "evaluated":
        return "Evaluated";
      case "evaluator_assigned":
        return "Evaluation Not Started";

      default:
        break;
    }
  };
  useEffect(() => {
    if (evaluation_ended_status === "success") {
      dynamicClear("evaluation_ended");
    }
  }, [evaluation_ended_status]);
  let columns = [
    {
      title: t("table:candidate"),
      dataIndex: "",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
      render: (data) => (
        <Pressable
          onPress={() => history.push(`${ROUTES.SUBMISSION_DETAIL}/${data.id}`)}
        >
          <Text color={"#0000ff"}>{data.user.name}</Text>
        </Pressable>
      ),
    },
    {
      title: t("table:question_paper"),
      dataIndex: "question_paper",
      key: "question_paper",
      sortable: false,
      align: "left",
      flex: 1,
      render: (question_paper) => <Text>{question_paper.title}</Text>,
    },
    {
      title: t("Submitted Date"),
      dataIndex: "uploaded_at",
      key: "status",
      sortable: false,
      align: "left",
      flex: 1,
      render: (date) =>
        date ? moment(new Date(date)).format("DD-MM-YYYY, h:mm a") : "",
    },
    {
      title: t("Assigned Date"),
      dataIndex: "evaluator_assigned_at",
      key: "status",
      sortable: false,
      align: "left",
      flex: 1,
      render: (date) =>
        date ? moment(new Date(date)).format("DD-MM-YYYY, h:mm a") : "",
    },
    {
      title: t("Corrected Date"),
      dataIndex: "evaluated_at",
      key: "status",
      sortable: false,
      align: "left",
      flex: 1,
      render: (date) =>
        date ? moment(new Date(date)).format("DD-MM-YYYY, h:mm a") : "",
    },
    {
      title: t("table:status"),
      dataIndex: "status",
      key: "status",
      sortable: false,
      align: "left",
      flex: 1,
      render: (status) => handleStatus(status),
    },
  ];
  const handleStatusChange = (status) => {
    setSelectedStatus(status);
    let keys = [{ key: "get_all_submissions", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_submission_query, {
        page_number: 1,
        page_limit: 10,
        status: status,
        question_paper_id: selected_question_paper,
      })
    );
  };
  const handleQuestionPaperChange = (question_paper) => {
    setSelectedQuestionPaper(question_paper);
    let keys = [{ key: "get_all_submissions", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_submission_query, {
        page_number: 1,
        page_limit: 10,
        status: selected_status,
        question_paper_id: question_paper,
      })
    );
  };
  return (
    <Box>
      <EvaluatorHeaderBar />
      <Box
        height={"80vh"}
        overflowY={"auto"}
        pb={{ base: 5, xs: 12, md: 5, lg: 5 }}
      >
        <Box bg="white" mt={10} px={{ base: 10, md: 20 }}>
          <Text fontSize="md" fontWeight="bold">
            {"Questions"}
          </Text>
        </Box>
        <Box bg="white" mt={10} px={{ base: 10, md: 20 }}>
          <Row gutter={16}>
            <Col xs={24} md={8}>
              <Col span={24}>
                <Text bold>Status</Text>
              </Col>
              <Col span={24}>
                <Form.Item style={{ width: "100%" }}>
                  <Select
                    defaultValue={selected_status}
                    onChange={handleStatusChange}
                    allowClear={true}
                    disabled={status ? true : false}
                    options={[
                      {
                        value: "evaluation_started",
                        label: "Partially evaluated",
                      },
                      {
                        value: "evaluator_assigned",
                        label: "Evaluation Not Started",
                      },
                      {
                        value: "evaluated",
                        label: "Evaluated",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Col>
            <Col xs={24} md={8}>
              <Col span={24}>
                <Text bold>Question</Text>
              </Col>
              <Col span={24}>
                <Form.Item style={{ width: "100%" }}>
                  <Select
                    defaultValue={selected_question_paper}
                    onChange={handleQuestionPaperChange}
                    allowClear={true}
                    options={question_papers?.map((x) => {
                      return { value: x.id, label: x.title };
                    })}
                  />
                </Form.Item>
              </Col>
            </Col>
          </Row>
        </Box>
        <Box
          width="100%"
          mt="10px"
          marginBottom="20px"
          px={{ base: 10, md: 20 }}
        >
          <VStack space={5}>
            <Wrapper>
              <Hidden till="md">
                <Table
                  columns={columns}
                  dataSource={submissions}
                  pagination={false}
                  loading={loading}
                />
              </Hidden>
              <Hidden from="md">
                {loading ? (
                  <Center mt={"5vh"}>
                    <Loader
                      type="spinner-default"
                      bgColor={"#349481"}
                      color={"#349481"}
                      size={50}
                    />
                  </Center>
                ) : (
                  submissions?.map((x) => (
                    <Pressable
                      onPress={() => {
                        history.push(`${ROUTES.SUBMISSION_DETAIL}/${x.id}`);
                      }}
                    >
                      <Card my="2">
                        <VStack>
                          <Text>{x?.user?.name}</Text>
                          <Text>{x?.question_paper?.title}</Text>
                          <Text>{x?.status}</Text>
                        </VStack>
                      </Card>
                    </Pressable>
                  ))
                )}
              </Hidden>
            </Wrapper>
            <Pagination
              total={pagination?.total_count}
              showSizeChanger={false}
              current={current}
              hideOnSinglePage={false}
              onChange={fetchData}
            />
          </VStack>
        </Box>
      </Box>
    </Box>
  );
};
export default SubmissionList;
