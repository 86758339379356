import React, { useEffect, useState } from "react";
import VForm from "@views/components/ui/antd_form";
import { Form, Divider, Button, Select, Input, InputNumber } from "antd";
import { Box, HStack, Text, useBreakpointValue } from "native-base";
import { useDispatch } from "react-redux";
import {
  dynamicRequest,
  create_question_paper_section_mutation,
  useDynamicSelector,
  update_question_paper_section_mutation,
} from "@services/redux";
import { useTranslation } from "react-i18next";

function DynamicField(props) {
  const { t } = useTranslation();
  const { initialValues, questionPaperId, actionType } = props;
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(1);
  const dispatch = useDispatch();

  const columns = useBreakpointValue({
    base: 1,
    md: 2,
  });
  const handleChange = (b, a) => {};

  const {
    loading: updateLoading,
    status: updateStatus,
    error: updateError,
  } = useDynamicSelector("update_question_paper_section");

  const {
    loading: createLoading,
    status: createStatus,
    error: createError,
  } = useDynamicSelector("create_question_paper_section");

  const updateQuestionPaperSection = (values) => {
    let keys = [{ key: "update_question_paper_section", loading: true }];
    let query = update_question_paper_section_mutation;
    let variables = {
      id: initialValues?.id,
      data: values,
    };
    dispatch(dynamicRequest(keys, query, variables));
  };

  const createQuestionPaperSection = (values) => {
    let keys = [{ key: "create_question_paper_section", loading: true }];
    let query = create_question_paper_section_mutation;
    let variables = {
      data: values,
    };
    dispatch(dynamicRequest(keys, query, variables));
  };

  const handleSubmit = (values) => {
    values.question_paper_id = questionPaperId;
    if (initialValues?.id && actionType === "edit") {
      updateQuestionPaperSection(values);
    } else {
      createQuestionPaperSection(values);
    }
  };

  const loadQuestionPaperSections = (should_load) => {
    let keys = [
      { key: "get_all_question_paper_sections", loading: should_load },
    ];
  };

  useEffect(() => {
    loadQuestionPaperSections(true);
  }, []);
  if (actionType === "add") form.resetFields();

  useEffect(() => {
    if (initialValues?.id && actionType === "edit") {
      form.setFieldsValue(initialValues);
    } else {
    }
  }, [initialValues, actionType]);

  return (
    <Form
      form={form}
      name={"Section Details"}
      layout={"vertical"}
      onFinish={handleSubmit}
      onValuesChange={handleChange}
      className="user_form ant-select-selector ant-select-selection-search"
    >
      <Box>
        <VForm.Grid columns={columns} spacingY={20} spacingX={10}>
          <VForm.TextBox
            label={t("Name")}
            field={"name"}
            // options={get_all_subjects}
            rules={[
              {
                required: true,
                message: "Name is Required",
              },
            ]}
          />
          <VForm.TextBox
            label={t("Tamil Name")}
            field={"ta_name"}
            // options={get_all_subjects}
            rules={[
              {
                required: true,
                message: "Name is Required",
              },
            ]}
          />
          <VForm.TextBox
            label={t("Code")}
            field={"code"}
            rules={[
              {
                required: true,
                message: "Code is Required",
              },
            ]}
          />
          <VForm.Select
            label={t("Type")}
            field={"type"}
            rules={[
              {
                required: true,
                message: "Type is Required",
              },
            ]}
            options={[
              { id: "objective", name: "Objective" },
              { id: "descriptive", name: "Descriptive" },
              {
                id: "objective_and_descriptive",
                name: "Objective & Descriptive",
              },
            ]}
          />
          <VForm.Number
            label={t("Maximum Mark")}
            field={"max_mark"}
            rules={[
              {
                required: true,
                message: "Maximum Mark is Required",
              },
            ]}
          />
          <VForm.Number
            label={t("No of Lines In Section")}
            field={"no_of_answer_lines"}
          />

          <VForm.TextBox label={t("Note")} field={"note"} />
          <VForm.TextBox label={t("Description")} field={"description"} />

          <VForm.Number
            label={t("Sort Order")}
            field={"sort_order"}
            rules={[
              {
                required: true,
                message: "Sort Order is Required",
              },
            ]}
          />
          <VForm.Number label={t("Negative Mark")} field={"negative_mark"} />
          {/* <VForm.TextBox label={t("Question Paper Id")} field={"question_paper_id"} /> */}
        </VForm.Grid>
        <HStack space="10" justifyContent={"flex-end"} marginRight="40px">
          <VForm.Button
            isLoading={createLoading || updateLoading}
            disabled={false}
          >
            {t("Submit")}
          </VForm.Button>
          <Button onClick={props.close}>{t("close")}</Button>
        </HStack>
      </Box>
    </Form>
  );
}

export default DynamicField;
