import React, { useEffect, useState } from "react";
import { Box, Button, HStack, Text, VStack } from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  get_all_candidates_query,
  dynamicRequest,
  useDynamicSelector,
  dynamicClear,
} from "@services/redux";
import Wrapper from "@views/components/ui/wrapper";
import { Pagination, Popconfirm, Table, Typography, Input } from "antd";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";

const CandidateList = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [current, setCurrent] = useState(1);
  const { t } = useTranslation();
  const { Search } = Input;
  const {
    items: get_all_candidates,
    pagination,
    loading,
  } = useDynamicSelector("get_all_users");

  const getAllCandidates = (should_load) => {
    let keys = [{ key: "get_all_users", loading: should_load }];
    dispatch(
      dynamicRequest(keys, get_all_candidates_query, {
        page_number: current,
        page_limit: 10,
      })
    );
  };

  const handleSearch = (search_txt) => {
    dispatch(
      dynamicRequest(keys, get_all_candidates_query, {
        search_string: search_txt,
      })
    );
  };
  const onShowSizeChange = (current, value) => {
    let keys = [{ key: "get_all_users", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_candidates_query, {
        page_number: current,
        page_limit: value,
      })
    );
  };
  const fetchData = (value) => {
    let keys = [{ key: "get_all_users", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_candidates_query, {
        page_number: value,
        page_limit: 10,
      })
    );
    setCurrent(value);
  };

  useEffect(() => {
    getAllCandidates(true);
  }, []);

  const formatResponsesEmail = (value) => {
    return value?.system_user?.email;
  };
  let columns = [
    {
      title: t("table:Name"),
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("Email"),
      dataIndex: "",
      key: "email",
      render: (record) => {
        return formatResponsesEmail(record);
      },
      sortable: false,
      align: "left",
      flex: 1,
    },

    {
      title: t("Mobile Number"),
      dataIndex: "mobile_number",
      key: "mobile_number",
      sortable: false,
      align: "left",
      flex: 1,
    },
  ];
  return (
    <>
      <Box top="10px" zIndex="2" bg="white">
        <Text fontSize="md" fontWeight="bold">
          {"Candidate Details"}
        </Text>
      </Box>
      <HStack space={3} justifyContent="flex-end" m={3}>
        {/* <Box marginTop={"5px"}>
          <Search placeholder="search" allowClear onSearch={handleSearch} />
        </Box> */}
      </HStack>
      <Box width="100%" mt="30px" marginBottom="20px">
        <VStack space={5}>
          <Wrapper>
            <Table
              columns={columns}
              dataSource={get_all_candidates}
              pagination={false}
              loading={loading}
            />
          </Wrapper>
          <Pagination
            total={pagination?.total_count}
            showSizeChanger={true}
            onShowSizeChange={onShowSizeChange}
            current={current}
            hideOnSinglePage={false}
            onChange={fetchData}
          />
        </VStack>
      </Box>
    </>
  );
};
export default CandidateList;
