const loadImage = (setRatio, imageUrl) => {
  const img = new Image();
  img.src = imageUrl;

  img.onload = () => {
    setRatio(img.width / img.height);
  };
  img.onerror = (err) => {};
};

export default loadImage;
