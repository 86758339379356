import React, { useState } from "react";
import {
  View,
  Heading,
  FormControl,
  Input,
  Button,
  Image,
  Box,
  Hidden,
} from "native-base";
// import memberImage from "@assets/images/c_p_image.jpg";
import { useWindowDimensions } from "react-native-web";

const ChangePasswordPage = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleChangePassword = () => {
    // Add your password change logic here
    console.log("Password changed!");
  };
  const windowDimensions = useWindowDimensions();
  return (
    <View flexDirection="row">
      <Hidden only={["sm", "xs"]}>
        <Box height={windowDimensions.height} width={["100%", "100%", "50%"]}>
          <Image
            // source={memberImage}
            alt="Member"
            resizeMode="cover"
            width="100%"
            height="100%"
          />
        </Box>
      </Hidden>

      <Box
        width={["100%", "100%", "50%"]}
        justifyContent="center"
        p={[30, 30, 150]}
        backgroundColor={"blueGray.100"}
      >
        <Heading mb={6}>Change Password</Heading>

        <FormControl mb={4}>
          <FormControl.Label>Current Password</FormControl.Label>
          <Input
            type="password"
            value={currentPassword}
            onChangeText={(value) => setCurrentPassword(value)}
          />
        </FormControl>

        <FormControl mb={4}>
          <FormControl.Label>New Password</FormControl.Label>
          <Input
            type="password"
            value={newPassword}
            onChangeText={(value) => setNewPassword(value)}
          />
        </FormControl>

        <FormControl mb={4}>
          <FormControl.Label>Confirm New Password</FormControl.Label>
          <Input
            type="password"
            value={confirmPassword}
            onChangeText={(value) => setConfirmPassword(value)}
          />
        </FormControl>

        <Button onPress={handleChangePassword}>Change Password</Button>
      </Box>
    </View>
  );
};

export default ChangePasswordPage;
