import { gql } from "@apollo/client";

export const mutation_bulk_upload_questions = gql`
  mutation createTotalQuestionPaper($data: question_multiple_input) {
    bulk_upload_questions: createTotalQuestionPaper(data: $data) {
      id
      status
      count
      error {
        status_code
        message
      }
    }
  }
`;
