import { Box, HStack, Text, VStack } from "native-base";
import React, { Component } from "react";
import "../../index.css";

import Scrollbars from "react-custom-scrollbars-2";

const Policy = (props) => {
  return (
    <Scrollbars>
      <Box my={5} alignItems={"center"}>
        <Text fontSize={16} bold textAlign={"center"}>
          Nokkam
        </Text>
      </Box>
      <Box>
        <Box mx={2} my={5}>
          At Nokkam App, accessible from https://aascmission60.vertace.org/, one
          of our main priorities is the privacy of our visitors. This Privacy
          Policy document contains types of information that is collected and
          recorded by Nokkam and how we use it.
          <br />
          If you have additional questions or require more information about our
          Privacy Policy, do not hesitate to contact us.
          <br />
          This Privacy Policy applies only to our online activities and is valid
          for visitors to our website with regards to the information that they
          shared and/or collect in Central Police App. This policy is not
          applicable to any information collected offline or via channels other
          than this website. Our Privacy Policy was created with the help of the
          TermsFeed Privacy Policy Generator.
        </Box>
        <Text mx={2} bold>
          Consent
        </Text>
        <Box my={5} mx={2}>
          By using our website, you hereby consent to our Privacy Policy and
          agree to its terms.
        </Box>
        <Text mx={2} bold>
          Information we collect
        </Text>
        <Box my={5} mx={2}>
          The personal information that you are asked to provide, and the
          reasons why you are asked to provide it, will be made clear to you at
          the point we ask you to provide your personal information.
          <br />
          We may change this Privacy Policy without notice from time to time
          without any notice to you. However, changes will be updated in the
          Privacy Policy page.
          <br />
          If you contact us directly, we may receive additional information
          about you such as your name, email address, phone number, the contents
          of the message and/or attachments you may send us, and any other
          information you may choose to provide.
          <br />
          When you register for an Account, we may ask for your contact
          information, including items such as name, company name, address,
          email address, and telephone number.
        </Box>
        <Text bold mx={2} my={5}>
          We use the information we collect in various ways, including to:
        </Text>

        <Box my={5} mx={2}>
          <ul>
            <li> Provide, operate, and maintain our website.</li>
            <li>Improve, personalize, and expand our website.</li>
            <li>
              Develop new products, services, features, and functionality.
            </li>
            <li>Provide, operate, and maintain our website.</li>
            <li>
              Communicate with you, either directly or through one of our
              partners, including for customer service, to provide you with
              updates and other information relating to the website, and for
              marketing and promotional purposes.
            </li>
            <li>Send you emails.</li>
            <li>Find and prevent fraud.</li>
          </ul>
        </Box>
        <Text mx={2} bold>
          Log Files
        </Text>
        <Box my={5} mx={2}>
          Central Police App follows a standard procedure of using log files.
          These files log visitors when they visit websites. All hosting
          companies do this and a part of hosting services' analytics. The
          information collected by log files include internet protocol (IP)
          addresses, browser type, Internet Service Provider (ISP), date and
          time stamp, referring/exit pages, and possibly the number of clicks.
          These are not linked to any information that is personally
          identifiable. The purpose of the information is for analyzing trends,
          administering the site, tracking users' movement on the website, and
          gathering demographic information.
        </Box>
        <Box my={5} mx={2}>
          You are responsible for safeguarding the confidentiality of your AASC
          Mission 60 login credentials such as your user id, password, OTP,
          etc., and for restricting access to your computer/mobile to prevent
          unauthorized access to your account. We, as a Company do not ask for
          Password and you are cautioned not to share your password to any
          persons. You agree to accept responsibility for all activities that
          occur under your account.
        </Box>
      </Box>
    </Scrollbars>
  );
};

export default Policy;
