import { gql } from "@apollo/client";


export const create_text_book_class_mutation = gql`
  mutation createTextBookClass($data: text_book_class_input) {
    create_text_book_class: createTextBookClass(data: $data) {
      id
      status
      count
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_text_book_class_mutation = gql`
  mutation deleteTextBookClass($id: String!) {
    delete_text_book_class: deleteTextBookClass(id: $id) {
      id
      status
      count
      error {
        status_code
        message
      }
    }
  }
`;

export const update_text_book_class_mutation = gql`
  mutation updateTextBookClass($id: String!, $data: text_book_class_input) {
    update_text_book_class: updateTextBookClass(id: $id, data: $data) {
      id
      status
      count
      error {
        status_code
        message
      }
    }
  }
`;

export const get_all_text_book_class_query = gql`
  query getAllTextBookClasses($text_book_board_id: String,  $page_number: Float, $page_limit: Float) {
    get_all_text_book_class: getAllTextBookClasses(text_book_board_id: $text_book_board_id, page_number: $page_number, page_limit: $page_limit) {
      items{
        id
        name
        text_book_board_id
        icon
        sort_order
        text_book_board{
            id
            name
            icon
            sort_order
        }
      }
      pagination{
        page_limit
        page_number
        total_count
      }
      error{
        status_code
        message
      }
    }
  }
`;

export const get_one_text_book_class_query = gql`
  query getTextBookClass($id: String!) {
    get_one_text_book_class: getTextBookClass(id: $id) {
        id
        name
        text_book_board_id
        icon
        sort_order
        text_book_board{
            id
            name
            icon
            sort_order
        }
      error{
        status_code
        message
      }
    }
  }
`;