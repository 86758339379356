import React, { useEffect, useState } from "react";
import VForm from "@views/components/ui/antd_form";
import { Form, Button } from "antd";
import { useDispatch } from "react-redux";
import {
  dynamicRequest,
  create_infographic_topics,
  update_infographic_topic,
  useDynamicSelector,
  get_all_topics_query,
} from "@services/redux";
import { useTranslation } from "react-i18next";
import { HStack, useBreakpointValue } from "native-base";

const InfographicTopicDetails = (props) => {
  const { t } = useTranslation();
  const { initialValues, topic_id, exam_notes } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { items: topics } = useDynamicSelector("get_all_topics");
  const { loading: create_loading } = useDynamicSelector(
    "createInfographicTopic"
  );
  const { loading: update_loading } = useDynamicSelector(
    "updateInfographicTopic"
  );

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
  }, [initialValues]);
  useEffect(() => {
    let keys = [{ key: "get_all_topics", loading: true }];
    dispatch(dynamicRequest(keys, get_all_topics_query));
  }, []);
  const handleSubmit = (values) => {
    if (initialValues?.id) {
      let variables = {
        json: { id: initialValues?.id, data: { ...values } },
      };
      let keys = [{ key: "updateInfographicTopic", loading: true }];
      dispatch(dynamicRequest(keys, update_infographic_topic, variables, "M"));
    } else {
      let variables = {
        json: { data: { ...values } },
      };
      let keys = [{ key: "createInfographicTopic", loading: true }];
      dispatch(dynamicRequest(keys, create_infographic_topics, variables, "M"));
    }
  };
  const columns = useBreakpointValue({
    base: 1,
    md: 2,
  });

  return (
    <Form
      form={form}
      name={"Note Details"}
      layout={"vertical"}
      onFinish={handleSubmit}
      className="user_form ant-select-selector ant-select-selection-search"
    >
      <VForm.TextBox
        label={t("Name")}
        field={"name"}
        rules={[
          {
            required: true,
            message: "Name is Required",
          },
        ]}
      />

      <HStack space="10" justifyContent={"flex-end"} marginRight="40px">
        <VForm.Button
          loading={initialValues?.id ? update_loading : create_loading}
          disabled={false}
        >
          {t("Submit")}
        </VForm.Button>
        <Button onClick={props.close}>{t("close")}</Button>
      </HStack>
    </Form>
  );
};
export default InfographicTopicDetails;
