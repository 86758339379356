import React, { useEffect } from "react";
import VForm from "@views/components/ui/antd_form";
import { Form, Button } from "antd";
import { useDispatch } from "react-redux";
import {
  dynamicRequest,
  create_topic_mutation,
  update_topic_mutation,
} from "@services/redux";
import { useTranslation } from "react-i18next";
import { HStack } from "native-base";

const subjectDetails = (props) => {
  const { t } = useTranslation();
  const { initialValues } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  const handleSubmit = (values) => {
    // values.icon = values.icon?.[0]?.url;
    if (initialValues?.id) {
      let variables = {
        id: initialValues?.id,
        data: values,
      };
      let keys = [{ key: "update_topic", loading: true }];
      dispatch(dynamicRequest(keys, update_topic_mutation, variables, "M"));
    } else {
      let variables = {
        data: values,
      };
      let keys = [{ key: "create_topic", loading: true }];
      dispatch(dynamicRequest(keys, create_topic_mutation, variables, "M"));
    }
  };

  return (
    <Form
      form={form}
      name={"Topic Detailss"}
      layout={"vertical"}
      onFinish={handleSubmit}
      className="user_form ant-select-selector ant-select-selection-search"
    >
      <VForm.TextBox
        label={t("Name")}
        field={"name"}
        rules={[
          {
            required: true,
            message: "Name is Required",
          },
        ]}
      />
      {/* <VForm.File
        label={t("Icon")}
        field={"icon"}
        rules={[
          {
            required: true,
            message: "Icon is Required",
          },
        ]}
      /> */}
      {/* <VForm.Number
        label={t("Sort Order")}
        field={"sort_order"}
        rules={[
          {
            required: true,
            message: "Sort Order is Required",
          },
        ]}
      /> */}
      <HStack space="10" justifyContent={"flex-end"} marginRight="40px">
        <VForm.Button loading={false} disabled={false}>
          {t("Submit")}
        </VForm.Button>
        <Button onClick={props.close}>{t("close")}</Button>
      </HStack>
    </Form>
  );
};
export default subjectDetails;
