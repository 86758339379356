import { HStack, Text, VStack } from 'native-base'
import React from 'react'
import ReactHtmlParser from "react-html-parser";
import AIMLOGO from "@assets/icons/aim_logo.png"

const SectionQuestionPdfEnglish = ({ question_ref, session_question, question_paper }) => {
    // const extractTextFromHTML = (html) => {
    //     const doc = new DOMParser().parseFromString(html, 'text/html');
    //     return doc.body.textContent || "";
    // };
    return (
        <div ref={question_ref} style={{ padding: "20px" }}>
            <VStack justifyContent={"center"} alignContent={"center"} alignItems={"center"}>
                <img src={AIMLOGO} style={{ width: "140px" }} />
                <Text bold fontSize={"20px"}>
                    Anna Administrative Staff Collage
                </Text>
                {/* <Text bold fontSize={"20px"}>
                    NOKKAM
                </Text> */}
                <Text bold my={"30px"} fontSize={"18px"}>
                    {`${question_paper?.question_paper_exam?.name} - ${question_paper?.name}`}
                </Text>
            </VStack>

            {session_question?.map((list) => {
                return <VStack style={{}}>
                    <HStack alignItems={"center"} justifyContent={"center"} mb={"10px"}>
                        <Text bold fontSize={"18px"}>{list?.name}</Text>
                    </HStack>
                    <VStack space={"30px"}>
                        {list?.section_questions?.map((question, index) => {
                            return (
                                <VStack>
                                    <HStack space={"2px"} width={"100%"} alignContent={"center"} alignItems={"baseLine"}>
                                        {`${index + 1}.`}
                                        <Text bold>
                                            {ReactHtmlParser(question?.content)}
                                        </Text>
                                    </HStack>
                                    <VStack space={"10px"} marginLeft={"40px"}>
                                        {question?.question_options?.map((list, index) => {
                                            return (
                                                <HStack space={"1px"} alignItems={"center"} justifyContent={"flex-start"}>
                                                    <Text bold >
                                                        {`${index + 1}.`}
                                                    </Text>
                                                    <Text >
                                                        {ReactHtmlParser(list)}
                                                    </Text>
                                                </HStack>
                                            )
                                        })}

                                        {/* <HStack space={"1px"} alignItems={"center"} justifyContent={"flex-start"} >
                                            <Text bold >
                                                Correct option :-
                                            </Text>
                                            <Text >
                                                {question?.correct_answer_option ||
                                                    '-'}
                                            </Text>
                                        </HStack> */}
                                    </VStack>
                                </VStack>
                            )
                        })}
                    </VStack>
                </VStack>

            })}

        </div>
    )
}
const SectionAnswersPdfEnglish = ({ question_ref, session_question, question_paper }) => {
    return (
        <div ref={question_ref} style={{ padding: "20px" }}>
            <VStack justifyContent={"center"} alignContent={"center"} alignItems={"center"}>
                <img src={AIMLOGO} style={{ width: "140px" }} />
                <Text bold fontSize={"20px"}>
                    Anna Administrative Staff Collage
                </Text>
                <Text bold my={"30px"} fontSize={"18px"}>
                    {`${question_paper?.question_paper_exam?.name} - ${question_paper?.name}`}
                </Text>
            </VStack>

            {session_question?.map((list) => {
                return <VStack style={{}}>
                    <HStack alignItems={"center"} justifyContent={"center"} mb={"10px"}>
                        <Text bold fontSize={"18px"}>{list?.name}</Text>
                    </HStack>
                    <VStack space={"30px"}>
                        {list?.section_questions?.map((question, index) => {
                            return (
                                <VStack>
                                    <HStack space={"2px"} width={"100%"} alignContent={"center"} alignItems={"baseLine"}>
                                        {`${index + 1}.`}
                                        <Text bold>
                                            {ReactHtmlParser(question?.content)}
                                        </Text>
                                    </HStack>
                                    <VStack space={"10px"} marginLeft={"40px"}>
                                        <HStack space={"1px"} alignItems={"center"} justifyContent={"flex-start"} >
                                            <Text bold >
                                                Correct option :-
                                            </Text>
                                            <Text >
                                                {question?.correct_answer_option ||
                                                    '-'}
                                            </Text>
                                        </HStack>
                                    </VStack>
                                </VStack>
                            )
                        })}
                    </VStack>
                </VStack>

            })}

        </div>
    )
}
const SectionQuestionPdfTamil = ({ question_ref, session_question, question_paper }) => {
    return (
        <div ref={question_ref} style={{ padding: "20px", lineBreak: "loose", lineHeight: "15px" }}>
            <VStack justifyContent={"center"} alignContent={"center"} alignItems={"center"}>
                <img src={AIMLOGO} style={{ width: "140px" }} />
                <Text bold fontSize={"20px"}>
                    Anna Administrative Staff Collage
                </Text>
                {/* <Text bold fontSize={"20px"}>
                    NOKKAM
                </Text> */}
                <Text bold my={"30px"} fontSize={"18px"}>
                    {`${question_paper?.question_paper_exam?.name} - ${question_paper?.name}`}
                </Text>
            </VStack>

            {session_question?.map((list) => {
                return <VStack style={{}}>
                    <HStack alignItems={"center"} justifyContent={"center"} mb={"10px"}>
                        <Text bold fontSize={"18px"}>{list?.name}</Text>
                    </HStack>
                    <VStack space={"30px"}>
                        {list?.section_questions?.map((question, index) => {
                            return (
                                <VStack>
                                    <HStack space={"2px"} width={"100%"} alignContent={"center"} alignItems={"baseLine"}>
                                        {`${index + 1}.`}
                                        <Text bold>
                                            {ReactHtmlParser(question?.ta_content)}
                                        </Text>
                                    </HStack>
                                    <VStack space={"10px"} marginLeft={"40px"}>
                                        {question?.ta_question_options?.map((list, index) => {
                                            return (
                                                <HStack space={"1px"} alignItems={"center"} justifyContent={"flex-start"}>
                                                    <Text bold >
                                                        {`${index + 1}.`}
                                                    </Text>
                                                    <Text >
                                                        {ReactHtmlParser(list)}
                                                    </Text>
                                                </HStack>
                                            )
                                        })}

                                        {/* <HStack space={"1px"} alignItems={"center"} justifyContent={"flex-start"} >
                                            <Text bold >
                                                Correct option :-
                                            </Text>
                                            <Text >
                                                {question?.correct_answer_option ||
                                                    '-'}
                                            </Text>
                                        </HStack> */}
                                    </VStack>
                                </VStack>
                            )
                        })}
                    </VStack>
                </VStack>

            })}

        </div>
    )
}
const SectionAnswerPdfTamil = ({ question_ref, session_question, question_paper }) => {
    return (
        <div ref={question_ref} style={{ padding: "20px", lineBreak: "loose", lineHeight: "15px" }}>
            <VStack justifyContent={"center"} alignContent={"center"} alignItems={"center"}>
                <img src={AIMLOGO} style={{ width: "140px" }} />
                <Text bold fontSize={"20px"}>
                    Anna Administrative Staff Collage
                </Text>
                {/* <Text bold fontSize={"20px"}>
                    NOKKAM
                </Text> */}
                <Text bold my={"30px"} fontSize={"18px"}>
                    {`${question_paper?.question_paper_exam?.name} - ${question_paper?.name}`}
                </Text>
            </VStack>

            {session_question?.map((list) => {
                return <VStack style={{}}>
                    <HStack alignItems={"center"} justifyContent={"center"} mb={"10px"}>
                        <Text bold fontSize={"18px"}>{list?.name}</Text>
                    </HStack>
                    <VStack space={"30px"}>
                        {list?.section_questions?.map((question, index) => {
                            return (
                                <VStack>
                                    <HStack space={"2px"} width={"100%"} alignContent={"center"} alignItems={"baseLine"}>
                                        {`${index + 1}.`}
                                        <Text bold>
                                            {ReactHtmlParser(question?.ta_content)}
                                        </Text>
                                    </HStack>
                                    <VStack space={"10px"} marginLeft={"40px"}>
                                        <HStack space={"1px"} alignItems={"center"} justifyContent={"flex-start"} >
                                            <Text bold >
                                                Correct option :-
                                            </Text>
                                            <Text >
                                                {question?.correct_answer_option ||
                                                    '-'}
                                            </Text>
                                        </HStack>
                                    </VStack>
                                </VStack>
                            )
                        })}
                    </VStack>
                </VStack>

            })}

        </div>
    )
}
const PdfFormats = (props) => {
    const { children } = props
    return (
        <>
            {children}
        </>
    )
}

PdfFormats.Tamil = SectionQuestionPdfTamil
PdfFormats.English = SectionQuestionPdfEnglish
PdfFormats.EnglishAnswers = SectionAnswersPdfEnglish
PdfFormats.TamilAnswers = SectionAnswerPdfTamil

export default PdfFormats
