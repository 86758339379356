import React from "react";
import { Box ,HStack,Text} from "native-base";
import vertace_logo from "@assets/images/vertace_logo.png";

const SidebarFooter = (props) => {
  return (
  <Box w={"100%"} h={"100%"} justifyContent="center" alignItems="center">
  <HStack>
    <Text color="#ffffff" fontSize={12} marginTop={1}>
      Powered By{" "}
    </Text>

    <a href="https://vertace.com/" target="blank">
      <img height="20" alt="vertace" src={vertace_logo} />
    </a>
  </HStack>
</Box>
  )
};

export default SidebarFooter;
