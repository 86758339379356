import React from "react";
import {
  Form,
  Divider,
  Button,
  Select,
  Input,
  InputNumber,
  DatePicker,
  Checkbox,
} from "antd";
import { useDispatch } from "react-redux";
import {
  Box,
  HStack,
  VStack,
  Text,
  Pressable,
  useBreakpointValue,
} from "native-base";
import VForm from "@views/components/ui/antd_form";
import {
  dynamicRequest,
  useDynamicSelector,
  create_question_mutation,
  update_question_mutation,
  update_question_paper_mutation,
  schedule_question_paper,
} from "@services/redux";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";

function PublishDate(props) {
  const [form] = Form.useForm();
  const { initialValues, close } = props;
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { loading: loading } = useDynamicSelector("scheduleQuestionPaper");

  const handleSubmit = (values) => {
    let variables = {
      publish_date: values?.publish_date,
      question_paper_id: initialValues?.id,
    };
    let keys = [{ key: "scheduleQuestionPaper", loading: true }];
    dispatch(dynamicRequest(keys, schedule_question_paper, variables, "M"));
  };
  useEffect(() => {
    form.setFieldsValue({
      publish_date: initialValues?.publish_date
        ? moment(parseInt(initialValues?.publish_date, 10))
        : "",
    });
  }, [initialValues]);
  const disabledDate = (current) => {
    return current <= moment().startOf("day");
  };
  //   d.isBefore(currentDate)
  return (
    <Form
      form={form}
      name={"Add Question"}
      layout={"vertical"}
      onFinish={handleSubmit}
      className="user_form ant-select-selector ant-select-selection-search"
    >
      <Box width="350px">
        <Form.Item label="Publish Date" name="publish_date">
          <DatePicker
            showTime
            disabledDate={disabledDate}
            disabled={initialValues?.is_published}
          />
        </Form.Item>

        <HStack space="10" justifyContent={"flex-end"} marginRight="30px">
          <VForm.Button isLoading={loading}>{t("Submit")}</VForm.Button>
          <Button onClick={props.close}>{t("close")}</Button>
        </HStack>
      </Box>
    </Form>
  );
}

export default PublishDate;
