import { gql } from "@apollo/client";

export const create_exam_mutation = gql`
  mutation createExam($data: exam_input) {
    create_exam: createExam(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_exam_mutation = gql`
  mutation updateExam($data: exam_input, $id: String!) {
    update_exam: updateExam(data: $data, id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_exam_mutation = gql`
  mutation deleteExam($id: String!) {
    delete_exam: deleteExam(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_all_exam_query = gql`
  query gelAllExam(
    $page_number: Float
    $page_limit: Float
    $board_id: String
    $is_published: Boolean
  ) {
    get_all_exams: getAllExams(
      page_number: $page_number
      page_limit: $page_limit
      board_id: $board_id
      is_published: $is_published
    ) {
      items {
        id
        name
        icon
        syllabus
        sort_order
        description
        exam_notes {
          id
          name
          exam_topics {
            id
            name
          }
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_one_Exam_query = gql`
  query getExam($id: String!) {
    get_exam: getExam(id: $id) {
      id
      name
      board_id
      sort_order
      class_schedule
      test_schedule
      notification
      syllabus
      notification_view_count
      notification_download_count
      syllabus_view_count
      syllabus_download_count
      class_schedule_view_count
      class_schedule_download_count
      test_schedule_view_count
      test_schedule_download_count
      exam_notes {
        id
        name
        exam_topics {
          id
          name
        }
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const update_exam_playlist_mutation = gql`
  mutation updateExamPlayListVideos($exam_id: String!) {
    update_exam_playlist_videos: updateExamPlayListVideos(exam_id: $exam_id) {
      id
      status
      count
      error {
        status_code
        message
      }
    }
  }
`;

export const update_exam_notes_order_mutation = gql`
  mutation updateExamOrderedNotes(
    $data: exam_note_order_input_list
    $exam_id: String!
  ) {
    update_exam_notes_order: updateExamOrderedNotes(
      json: $data
      exam_id: $exam_id
    ) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
