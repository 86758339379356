import { gql } from "@apollo/client";

export const evaluator_dashboard_query = gql`
  query evaluatorPaperCount {
    evaluator_dashboard: evaluatorPaperCount {
      evaluator_id
      not_started
      started
      completed
    }
  }
`;
