import React, { useState } from "react";
import { ROUTES } from "@views/routes/my_routes";
import { Box, VStack } from "native-base";
import ScrollView from "@views/components/ui/scroll_view";
import HeaderBar from "@views/components/ui/header_bar";
import FooterBar from "@views/components/ui/footer_bar";
import SideBar from "@views/components/ui/side_bar";
import SideBarHeader from "@views/components/modules/sidebar/sidebar_header";
import SideBarFooter from "@views/components/modules/sidebar/sidebar_footer";
import { FaGem, FaHeart } from "react-icons/fa";
import { GiPapers } from "react-icons/gi";
import { RxDashboard } from "react-icons/rx";
import { BsGraphUp, BsPeopleFill } from "react-icons/bs";
import { FaSquarespace } from "react-icons/fa";
import { BsBookFill } from "react-icons/bs";
import { MdNotificationsNone } from "react-icons/md";
import { IoIosNotifications } from "react-icons/io";
import { AiOutlineFileText } from "react-icons/ai";
import logo from "@assets/icons/nokkam.png";

const Layout1 = ({ children }) => {
  console.log("children", children);
  let menu = [
    {
      title: "Dashboard",
      icon: <RxDashboard />,
      url: ROUTES.DASHBOARD,
    },
    {
      title: "Boards",
      icon: <FaGem />,
      url: ROUTES.BOARD_LIST,
    },
    {
      title: "Topics",
      icon: <FaGem />,
      url: ROUTES.TOPIC_LIST,
    },
    // {
    //   title: "Question Papers",
    //   icon: <GiPapers />,
    //   url: ROUTES.QUESTIONS_LIST,
    // },
    {
      title: "Evaluators",
      icon: <FaGem />,
      url: ROUTES.EVALUATORS_LIST,
    },

    // {
    //   title: "Response",
    //   icon: <FaSquarespace />,
    //   url: ROUTES.RESPONSES_LIST,
    // },
    // {
    //   title: "Response",
    //   icon: <FaGem />,
    //   url: ROUTES.RESPONSES_LIST,
    // },
    {
      title: "Candidates",
      icon: <BsPeopleFill />,
      url: ROUTES.CANDIDATES_LIST,
    },
    {
      title: "Text Book",
      icon: <BsBookFill />,
      url: ROUTES.TEXT_BOOK,
    },
    {
      title: "Notification",
      icon: <IoIosNotifications />,
      url: ROUTES.NOTIFICATION_LIST,
    },
    {
      title: "Infographic Topics",
      icon: <BsGraphUp />,
      url: ROUTES.INFOGRAPHIC_TOPICS_LIST,
    },
    {
      title: "Reports",
      icon: <AiOutlineFileText />,
      url: ROUTES.REPORT,
    },
  ];

  return (
    <Box flexDirection="row" flexGrow="1">
      <SideBar
        image={
          logo
        }
        iconShape="round"
        iconBgColor={"none"}
        iconColor={"#ffffff"}
        iconSize={20}
        iconSelectedColor={"#330000"}
        titleColor={"#adadad"}
        titleActiveColor={"#ffffff"}
        headerHeight={"150px"}
        footerHeight="50px"
        menu={menu}
        headerComponent={<SideBarHeader />}
        footerComponent={<SideBarFooter />}
      />
      <VStack flex={1}>
        <HeaderBar />
        <ScrollView flex={1}>
          <Box flex={1} ml="10px" mr="10px">
            {children}
          </Box>
        </ScrollView>
        <FooterBar />
      </VStack>
    </Box>
  );
};
export default Layout1;
