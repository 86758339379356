import { gql } from "@apollo/client";

export const create_infographic_note = gql`
  mutation createInfographicNote($json: create_infographic_note_input) {
    createInfographicNote(json: $json) {
      id
      status
      count
      error {
        status_code
        message
      }
    }
  }
`;

export const update_infographic_note = gql`
  mutation updateInfographicNote($json: update_infographic_note_input) {
    updateInfographicNote(json: $json) {
      id
      status
      count
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_infographic_note = gql`
  mutation deleteInfographicNote($json: delete_infographic_note_input) {
    deleteInfographicNote(json: $json) {
      id
      status
      count
      error {
        status_code
        message
      }
    }
  }
`;
export const get_one_infographic_note = gql`
  query getInfographicNote($json: get_infographic_note_input) {
    getInfographicNote(json: $json) {
      id
      name
      file_url
      infographic_topic_id
      sort_order
      infographic_topic {
        id
        name
      }
    }
  }
`;

export const get_all_infographic_notes = gql`
  query getInfographicNotes($json: get_all_infographic_note_input) {
    getInfographicNotes(json: $json) {
      items {
        id
        name
        file_url
        infographic_topic_id
        sort_order
        infographic_topic {
          id
          name
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
